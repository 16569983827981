import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Select from "react-select";
import { headApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import { localApi, credentials } from "utils/imgApi";
import { useHistory, useLocation } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import Close from "@material-ui/icons/Close";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import { IoLogoWhatsapp } from "react-icons/io";
import { ImMobile } from "react-icons/im";
import { MdEmail } from "react-icons/md";
import { TiPhoneOutline } from "react-icons/ti";
import { TiPhone } from "react-icons/ti";
import SweetAlert from "react-bootstrap-sweetalert";
import styles from "assets/jss/material-dashboard-pro-react/views/viewCartStyle";
import styDrop from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import style from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import logo from "assets/img/apple-admin-icon.png";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(styDrop);
const useStyl = makeStyles(style);

export default function ViewPendingCart() {
  const [adminAddress, setAdminAddress] = React.useState([]);
  const [regAddress1, setRegAddress1] = React.useState([]);
  const [regAddress2, setRegAddress2] = React.useState([]);
  const [client, setClient] = React.useState([]);
  const [getItem, setGetItem] = React.useState([]);
  const [viewCart, setViewCart] = React.useState([]);
  // const [partyDetails, setPartyDetails] = React.useState([]);
  const [clientId, setClientId] = React.useState("");
  const [clientid, setClientid] = React.useState("");
  const [bootselect, setBootSelect] = React.useState("");
  const [reg, setReg] = React.useState("");

  const [cartId, setCartId] = React.useState();
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [submitteds, setSubmitteds] = React.useState(false);
  const [submittedConfirm, setSubmittedConfirm] = React.useState(false);

  const classes = useStyles();
  const classe = useStyle();
  const history = useHistory();
  const classSweet = useStyl();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const { state } = useLocation();
  //   console.log(state);

  if (state === null) {
    history.push("/admin/dashboard");
  }

  async function getClient() {
    const api = localApi + `/clientGroup/list/byAdminId/${userObj.id}`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await result.json();
    setClient(getResult);
    // console.log(getResult.filter((f) => f.id === state && state.cId));
  }

  async function getProd() {
    const get = localApi + `/adminAddress/list/byAdminId/${userObj.id}`;
    const results = await fetch(get, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const res = await results.json();
    setAdminAddress(
      res.filter((a) => a.addressType === "GSTIN ADDRESS" && a.active === true)
    );
  }

  async function getPendingCart() {
    const api =
      localApi +
      `/regAddress/list/byAdminAndRegisterId/${userObj && userObj.id}/${
        state && state.rId
      }`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await result.json();
    //   console.log(getResult);
    // setRegAddress1(getResult[0] && getResult[0] ? getResult[0] : "");
    // // setRegAddress2(getResult[1] && getResult[1] ? getResult[1] : "");
    // setRegAddress2(
    //   getResult[0] && getResult[0].active === true
    //     ? getResult[0]
    //     : getResult[1]
    //     ? getResult[1]
    //     : ""
    // );

    const gstAddress =
      getResult &&
      getResult.filter((f) =>
        f && f.addressType === "GSTIN ADDRESS"
          ? f.addressType === "GSTIN ADDRESS"
          : f.addressType === "AADHAR ADDRESS"
          ? f.addressType === "AADHAR ADDRESS"
          : f.addressType === "OTHER ADDRESS"
          ? f.addressType === "OTHER ADDRESS"
          : ""
      );
    // console.log(gstAddress);
    const shippingAddress =
      getResult &&
      getResult.filter(
        (f) => f.addressType != "GSTIN ADDRESS" && f.active != true
      );
    // console.log(shippingAddress[0]);

    setRegAddress1(gstAddress[0]);
    setRegAddress2(shippingAddress[0]);

    axios
      .get(
        localApi +
          `/cart/pendingCart/byAdminAndClientIdAndAcitve/${
            userObj && userObj.id
          }/${state && state.cId}?active=0`,
        {
          headers: {
            Authorization: `Basic ${credentials}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        if (res.data.length === 0) {
          setSubmitted(true);
          alertMsgOps("Data Not Found");
        } else {
          setViewCart(res && res.data);
          // console.log(res.data);
        }
      });
  }

  useEffect(() => {
    getProd();
    getClient();
    getPendingCart();
  }, []);

  //   const handleRemove = (c) => {
  //     setSubmitteds(true);
  //     setCartId(c);
  //   };
  //   const handleConfirm = () => {
  //     fetch(
  //       localApi +
  //         `/cart/delete/byAdminAndCartId/${userObj.id}/${cartId.id}`,
  //       {
  //         method: "DELETE",
  //       }
  //     )
  //       .then((res) => res.json())
  //       .then((res) => {
  //         // console.log(res);
  //         if (res.message === "Deleted Successfully !!!") {
  //           alertTypeOps("success");
  //           alertMsgOps("Deleted Succesfully !!!");
  //           setSubmittedConfirm(true);
  //         }
  //       });
  //   };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  const hideAlerts = () => {
    setSubmitteds(false);
    setSubmittedConfirm(false);
    window.location.reload(false);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
    }),
    menu: (state) => ({
      color: "#d81b60",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  var totalCal = 0;
  var totalCalMix = 0;
  var totalSum = 0;

  return (
    <div className={classes.productPage}>
      <GridContainer justify="center" alignItems="center">
        {submitted ? (
          <SweetAlert
            type={alertType}
            style={{ display: "block", marginTop: "-100px" }}
            title={alertMsg}
            onConfirm={() => hideAlert()}
            confirmBtnCssClass={classSweet.button + " " + classSweet.success}
          />
        ) : null}
        {submitteds ? (
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "100px" }}
            title="Are you sure?"
            //   onConfirm={handleConfirm}
            onCancel={() => hideAlerts()}
            confirmBtnCssClass={classSweet.button + " " + classSweet.success}
            cancelBtnCssClass={classSweet.button + " " + classSweet.danger}
            confirmBtnText="Yes, delete it!"
            cancelBtnText="Cancel"
            showCancel
          />
        ) : null}

        {submittedConfirm ? (
          <SweetAlert
            success
            style={{ display: "block", marginTop: "100px" }}
            title="Deleted!"
            onConfirm={() => hideAlerts()}
            onCancel={() => hideAlerts()}
            confirmBtnCssClass={classSweet.button + " " + classSweet.success}
          >
            Your imaginary file has been deleted.
          </SweetAlert>
        ) : null}

        <div className={classes.borders + " " + classes.container}>
          <GridContainer className={classes.borderLine}>
            <GridItem md={2} sm={2} xs={2}>
              <img
                src={
                  userObj.admin.logo != null
                    ? `https://inshabangles.com/imgstore/original_logo/${userObj.admin.logo}`
                    : logo
                }
                style={{ marginTop: "10px", height: "100px" }}
              />
            </GridItem>
            <GridItem
              md={8}
              sm={8}
              xs={8}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <span className={classes.invoiceName}>PER FORMA INVOICE</span>
              <br />
              <span className={classes.companyName}>
                {adminAddress.map((a) => a.company)}
              </span>
              <br />
              <div className={classes.space}>
                <span className={classes.address}>
                  {adminAddress.map((a) => a.plotHouseNo)}{" "}
                  {adminAddress.map((a) => a.areaColony)}{" "}
                  {adminAddress.map((a) => a.landMark)}{" "}
                  {adminAddress.map((a) => a.road)}
                  <br />
                  {adminAddress.map((a) => a.city)} {"-"}{" "}
                  {adminAddress.map((a) => a.zipCode)},{" "}
                  {adminAddress.map((a) => a.state)}, INDIA.
                  <br />{" "}
                  <strong className={classes.gst}>
                    GSTIN : {adminAddress.map((a) => a.gstIn)}
                  </strong>{" "}
                </span>
                <br />
                <span className={classes.mobileTel}>
                  <ImMobile /> {adminAddress.map((a) => a.mobile)}{" "}
                  <IoLogoWhatsapp /> {adminAddress.map((a) => a.whatsApp)}{" "}
                  <MdEmail /> {userObj.email}
                </span>
              </div>
            </GridItem>
            <GridItem md={2} sm={2} xs={2} className={classes.originalCopy}>
              <span>Original Copy</span>
            </GridItem>
          </GridContainer>
          <GridContainer className={classes.borderLine}>
            <GridItem
              md={6}
              sm={6}
              xs={6}
              className={classes.space + " " + classes.verticalLine}
            >
              <span
                className={classes.invoiceDetailsHead}
                style={{ textDecorationLine: "underline" }}
              >
                Billed to :-
              </span>
              <br />
              <span className={classes.invoicedetailCom}>
                {regAddress1 && regAddress1.company
                  ? regAddress1.company
                  : regAddress1 &&
                    regAddress1.firstName + " " + regAddress1 &&
                    regAddress1.lastName}
              </span>
              <br />
              <span className={classes.invoicedetail}>
                {regAddress1 && regAddress1.plotHouseNo}{" "}
                {regAddress1 && regAddress1.areaColony}
              </span>
              <br />
              <span className={classes.invoicedetail}>
                {regAddress1 && regAddress1.landMark}{" "}
                {regAddress1 && regAddress1.road}{" "}
                {regAddress1 && regAddress1.zipCode}
              </span>{" "}
              <br />
              <span className={classes.invoicedetail}>
                {regAddress1 && regAddress1.city}{" "}
                {regAddress1 && regAddress1.state}
              </span>
              <br />
              <span className={classes.invoicedetail}>
                {regAddress1 && regAddress1.mobile ? <ImMobile /> : ""}{" "}
                {regAddress1 && regAddress1.mobile ? regAddress1.mobile : ""}{" "}
                {regAddress1 && regAddress1.whatsApp ? <IoLogoWhatsapp /> : ""}{" "}
                {regAddress1 && regAddress1.whatsApp
                  ? regAddress1.whatsApp
                  : ""}
              </span>
              <br />
              <span className={classes.invoicedetail + " " + classes.gstIn}>
                {regAddress1 && regAddress1.gstIn
                  ? "GSTIN / UIN"
                  : "Aadhar No."}{" "}
                &nbsp;&nbsp;:{" "}
                {regAddress1 && regAddress1.gstIn
                  ? regAddress1.gstIn
                  : regAddress1 && regAddress1.aadharNumber}
              </span>
            </GridItem>
            <GridItem md={6} sm={6} xs={6} className={classes.space}>
              <span
                className={classes.invoiceDetailsHead}
                style={{ textDecorationLine: "underline" }}
              >
                Shipped to :-
              </span>
              <br />
              <span className={classes.invoicedetailCom}>
                {regAddress2 && regAddress2.company
                  ? regAddress2.company
                    ? regAddress2.company
                    : regAddress2 &&
                      regAddress2.firstName + " " + regAddress2 &&
                      regAddress2.lastName
                  : regAddress1.company
                  ? regAddress1.company
                  : regAddress1 &&
                    regAddress1.firstName + " " + regAddress1 &&
                    regAddress1.lastName}
                {/* {regAddress2 && regAddress2.company
                    ? regAddress2.company
                    : regAddress2 &&
                      regAddress2.firstName + " " + regAddress2 &&
                      regAddress2.lastName} */}
              </span>
              <br />
              <span className={classes.invoicedetail}>
                {regAddress2 && regAddress2.plotHouseNo
                  ? regAddress2.plotHouseNo
                  : regAddress1 && regAddress1.plotHouseNo}{" "}
                {regAddress2 && regAddress2.areaColony
                  ? regAddress2.areaColony
                  : regAddress1 && regAddress1.areaColony}
              </span>{" "}
              <br />
              <span className={classes.invoicedetail}>
                {regAddress2 && regAddress2.landMark
                  ? regAddress2.landMark
                  : regAddress1 && regAddress1.landMark}{" "}
                {regAddress2 && regAddress2.road
                  ? regAddress2.road
                  : regAddress1 && regAddress1.road}{" "}
                {regAddress2 && regAddress2.zipCode
                  ? regAddress2.zipCode
                  : regAddress1 && regAddress1.zipCode}{" "}
              </span>{" "}
              <br />
              <span className={classes.invoicedetail}>
                {regAddress2 && regAddress2.city
                  ? regAddress2.city
                  : regAddress1 && regAddress1.city}{" "}
                {regAddress2 && regAddress2.state
                  ? regAddress2.state
                  : regAddress1 && regAddress1.state}
              </span>
              <br />
              <span className={classes.invoicedetail}>
                {regAddress2 && regAddress2.mobile ? (
                  <ImMobile />
                ) : regAddress1 && regAddress1.mobile ? (
                  <ImMobile />
                ) : (
                  ""
                )}{" "}
                {regAddress2 && regAddress2.mobile
                  ? regAddress2.mobile
                  : regAddress1 && regAddress1.mobile
                  ? regAddress1.mobile
                  : ""}{" "}
                {regAddress2 && regAddress2.whatsApp ? (
                  <TiPhoneOutline />
                ) : regAddress1 && regAddress1.whatsApp ? (
                  <TiPhoneOutline />
                ) : (
                  ""
                )}{" "}
                {regAddress2 && regAddress2.whatsApp
                  ? regAddress2.whatsApp
                  : regAddress1 && regAddress1.whatsApp
                  ? regAddress1.whatsApp
                  : ""}
              </span>
              <br />
              <span className={classes.invoicedetail + " " + classes.gstIn}>
                {regAddress2 && regAddress2.gstIn
                  ? regAddress2.gstIn
                    ? "GSTIN / UIN :"
                    : "Aadhar No. :"
                  : regAddress1.gstIn
                  ? "GSTIN / UIN :"
                  : "Aadhar No. :"}
                {/* {regAddress2 && regAddress2.gstIn
                    ? "GSTIN / UIN :"
                    : "Aadhar No. :"}{" "} */}
                {regAddress2 && regAddress2.gstIn
                  ? regAddress2.gstIn
                    ? regAddress2.gstIn
                    : regAddress2 && regAddress2.aadharNumber
                  : regAddress1.gstIn
                  ? regAddress1.gstIn
                  : regAddress1 && regAddress1.aadharNumber}
                {/* {regAddress2 && regAddress2.gstIn
                    ? regAddress2.gstIn
                    : regAddress2 && regAddress2.aadharNumber} */}
              </span>
            </GridItem>
          </GridContainer>
          <Table
            tableHead={[
              <span className={classes.tableHead} key="sr">
                Sr.
                {/* <br /> */}
                No.
              </span>,
              "",
              <span className={classes.tableHead} key="des">
                Description of Goods
              </span>,
              <span
                className={classes.tableHead + " " + classes.headRight}
                key="ctn"
              >
                Ctn
              </span>,
              <span
                className={classes.tableHead + " " + classes.headRight}
                key="qty"
              >
                Quantity
              </span>,
              <span
                className={classes.tableHead + " " + classes.headRight}
                key="pri"
              >
                Price
              </span>,
              <span
                className={classes.tableHead + " " + classes.headRight}
                key="amt"
              >
                Amount
              </span>,
              "",
            ]}
            tableData={[
              ...viewCart.map((c, index) => [
                <span className={classes.listNumber} key={c.id}>
                  {index + 1}
                </span>,
                <div className={classes.imgContainer} key={c.id}>
                  Date: {c.createDate.slice(0, -19)}
                  <img
                    src={
                      c.cartImage[0].cpanelPath
                    }
                    className={classes.img}
                    style={{ height: "140px" }}
                  />
                  {/* {c.cartImage.map((ci) => (
                    <img
                    key={ci.id}
                      src={
                        ci.cropImageUrl +
                        "?id=" +
                        ci.imgId
                      }
                      alt="..."
                      className={classes.img}
                      style={{height: "140px"}}
                    />
                    ))} */}
                </div>,
                <span key={c.id}>
                  <h6 className={classes.listDescriptions}>
                    {c.cartChild.map((f) =>
                      f.title === "Plating Gold Plated" ? f.title.slice(8) : ""
                    )}{" "}
                    {c.parentTitle} {c.rawTitle} <br />
                    {c.categoryTitle} with {c.packingType}
                    <br />
                    {c.readyGram * c.setsPcs} Avg Gram of {c.setsDesc}
                    <br />
                    Sets Price :{" "}
                    {c.gstCash === false
                      ? (c.cashPrice / c.box).toFixed(2)
                      : (c.taxInclude / c.box).toFixed(2)}
                    /- {c.gstCash === false ? "Cash" : "IncTax"}
                    <br />
                    Metal Rate : ₹ {c.rawPrice + c.rawDifferent}/- Advance : ₹
                    {Math.round(
                      c.metalGram *
                        c.setsPcs *
                        c.box *
                        c.sizeTotal *
                        (c.rawPrice + c.rawDifferent)
                    )}
                  </h6>
                  {/* <br /> */}
                  {/* <small className={classes.listDescriptions}>
                      {c.readyGram * c.sets.pcs} Avg Gram of{" "}
                      {c.sets.setDesc}
                      {(
                        c.readyGram * c.sets.pcs +
                        (c?.mixmmList
                          ? c.mixmmList
                              .map((mix) => mix.sets.pcs)
                              .reduce((a, b) => a + b, 0) *
                            c.mixmmList
                              .map((mix) => mix.metalOption.readygram)
                              .reduce((a, b) => a + b, 0)
                          : 0)
                      ).toFixed(4)}{" "}
                      Avg Gram of{" "}
                      {c.sets.pcs +
                        (c?.mixmmList
                          ? c.mixmmList
                              .map((mix) => mix.sets.pcs)
                              .reduce((a, b) => a + b, 0)
                          : 0)}{" "}
                      {c.sets.setDesc.slice(2)}
                    </small> */}
                  {/* <br /> */}
                  {/* <small className={classes.listDescription}>
                      Sets Price :{" "}
                      {c.gstCash === false
                        ? (c.cashPrice / c.box.box).toFixed(2)
                        : (c.taxInclude / c.box.box).toFixed(2)}
                      /-{" "}IncTax
                    </small> */}
                  {/* <br /> */}
                  {/* <small className={classes.listDescriptions}> */}
                  {/* Metal Rate : ₹ {c.rawPrice.price + c.rawPrice.different}/- Advance : ₹
                      {Math.round(c.metalGram * c.sets.pcs * c.box.box * c.productSize.grandTotal * (c.rawPrice.price + c.rawPrice.different))} */}
                  {/* {Math.round(
                        c.metalOption.metalgram *
                          c.sets.pcs *
                          c.box.box *
                          c.total *
                          c.metal.price +
                          (c.mixmmList && c.mixmmList
                            ? c.mixmmList
                                .map(
                                  (m) =>
                                    m.metalOption.metalgram *
                                    m.sets.pcs *
                                    m.metal.price
                                )
                                .reduce((a, b) => a + b, 0) *
                              c.box.box *
                              c.total
                            : "")
                      )} */}
                  {/* /- */}

                  {/* </small> */}
                  {/* <br /> */}

                  <GridContainer className={classes.sizePadding}>
                    {c.size1x8 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.size1x8 == null ? "" : c.size1x8 == 0 ? "" : "1x8"}
                        <br />
                        {c.size1x8 == null
                          ? ""
                          : c.size1x8 == 0
                          ? ""
                          : c.size1x8}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.size1x10 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.size1x10 == null
                          ? ""
                          : c.size1x10 == 0
                          ? ""
                          : "1x10"}
                        <br />
                        {c.size1x10 == null
                          ? ""
                          : c.size1x10 == 0
                          ? ""
                          : c.size1x10}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.size1x12 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.size1x12 == null
                          ? ""
                          : c.size1x12 == 0
                          ? ""
                          : "1x12"}
                        <br />
                        {c.size1x12 == null
                          ? ""
                          : c.size1x12 == 0
                          ? ""
                          : c.size1x12}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.size1x14 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.size1x14 == null
                          ? ""
                          : c.size1x14 == 0
                          ? ""
                          : "1x14"}
                        <br />
                        {c.size1x14 == null
                          ? ""
                          : c.size1x14 == 0
                          ? ""
                          : c.size1x14}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.size2x0 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.size2x0 == null ? "" : c.size2x0 == 0 ? "" : "2x0"}
                        <br />
                        {c.size2x0 == null
                          ? ""
                          : c.size2x0 == 0
                          ? ""
                          : c.size2x0}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.size2x2 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.size2x2 == null ? "" : c.size2x2 == 0 ? "" : "2x2"}
                        <br />
                        {c.size2x2 == null
                          ? ""
                          : c.size2x2 == 0
                          ? ""
                          : c.size2x2}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.size2x4 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.size2x4 == null ? "" : c.size2x4 == 0 ? "" : "2x4"}
                        <br />
                        {c.size2x4 == null
                          ? ""
                          : c.size2x4 == 0
                          ? ""
                          : c.size2x4}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.size2x6 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.size2x6 == null ? "" : c.size2x6 == 0 ? "" : "2x6"}
                        <br />
                        {c.size2x6 == null
                          ? ""
                          : c.size2x6 == 0
                          ? ""
                          : c.size2x6}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.size2x8 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.size2x8 == null ? "" : c.size2x8 == 0 ? "" : "2x8"}
                        <br />
                        {c.size2x8 == null
                          ? ""
                          : c.size2x8 == 0
                          ? ""
                          : c.size2x8}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.size2x10 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.size2x10 == null
                          ? ""
                          : c.size2x10 == 0
                          ? ""
                          : "2x10"}
                        <br />
                        {c.size2x10 == null
                          ? ""
                          : c.size2x10 == 0
                          ? ""
                          : c.size2x10}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.size2x12 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.size2x12 == null
                          ? ""
                          : c.size2x12 == 0
                          ? ""
                          : "2x12"}
                        <br />
                        {c.size2x12 == null
                          ? ""
                          : c.size2x12 == 0
                          ? ""
                          : c.size2x12}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.size2x14 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.size2x14 == null
                          ? ""
                          : c.size2x14 == 0
                          ? ""
                          : "2x14"}
                        <br />
                        {c.size2x14 == null
                          ? ""
                          : c.size2x14 == 0
                          ? ""
                          : c.size2x14}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.size3x0 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.size3x0 == null ? "" : c.size3x0 == 0 ? "" : "3x0"}
                        <br />
                        {c.size3x0 == null
                          ? ""
                          : c.size3x0 == 0
                          ? ""
                          : c.size3x0}
                      </span>
                    ) : (
                      ""
                    )}
                  </GridContainer>
                  <GridContainer className={classes.sizePadding}>
                    {c.size1x9 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.size1x9 == null ? "" : c.size1x9 == 0 ? "" : "1x9"}
                        <br />
                        {c.size1x9 == null
                          ? ""
                          : c.size1x9 == 0
                          ? ""
                          : c.size1x9}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.size1x11 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.size1x11 == null
                          ? ""
                          : c.size1x11 == 0
                          ? ""
                          : "1x11"}
                        <br />
                        {c.size1x11 == null
                          ? ""
                          : c.size1x11 == 0
                          ? ""
                          : c.size1x11}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.size1x13 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.size1x13 == null
                          ? ""
                          : c.size1x13 == 0
                          ? ""
                          : "1x13"}
                        <br />
                        {c.size1x13 == null
                          ? ""
                          : c.size1x13 == 0
                          ? ""
                          : c.size1x13}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.size1x15 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.size1x15 == null
                          ? ""
                          : c.size1x15 == 0
                          ? ""
                          : "1x15"}
                        <br />
                        {c.size1x15 == null
                          ? ""
                          : c.size1x15 == 0
                          ? ""
                          : c.size1x15}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.size2x1 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.size2x1 == null ? "" : c.size2x1 == 0 ? "" : "2x1"}
                        <br />
                        {c.size2x1 == null
                          ? ""
                          : c.size2x1 == 0
                          ? ""
                          : c.size2x1}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.size2x3 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.size2x3 == null ? "" : c.size2x3 == 0 ? "" : "2x3"}
                        <br />
                        {c.size2x3 == null
                          ? ""
                          : c.size2x3 == 0
                          ? ""
                          : c.size2x3}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.size2x5 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.size2x5 == null ? "" : c.size2x5 == 0 ? "" : "2x5"}
                        <br />
                        {c.size2x5 == null
                          ? ""
                          : c.size2x5 == 0
                          ? ""
                          : c.size2x5}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.size2x7 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.size2x7 == null ? "" : c.size2x7 == 0 ? "" : "2x7"}
                        <br />
                        {c.size2x7 == null
                          ? ""
                          : c.size2x7 == 0
                          ? ""
                          : c.size2x7}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.size2x9 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.size2x9 == null ? "" : c.size2x9 == 0 ? "" : "2x9"}
                        <br />
                        {c.size2x9 == null
                          ? ""
                          : c.size2x9 == 0
                          ? ""
                          : c.size2x9}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.size2x11 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.size2x11 == null
                          ? ""
                          : c.size2x11 == 0
                          ? ""
                          : "2x11"}
                        <br />
                        {c.size2x11 == null
                          ? ""
                          : c.size2x11 == 0
                          ? ""
                          : c.size2x11}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.size2x13 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.size2x13 == null
                          ? ""
                          : c.size2x13 == 0
                          ? ""
                          : "2x13"}
                        <br />
                        {c.size2x13 == null
                          ? ""
                          : c.size2x13 == 0
                          ? ""
                          : c.size2x13}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.size2x15 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.size2x15 == null
                          ? ""
                          : c.size2x15 == 0
                          ? ""
                          : "2x15"}
                        <br />
                        {c.size2x15 == null
                          ? ""
                          : c.size2x15 == 0
                          ? ""
                          : c.size2x15}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.size3x1 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.size3x1 == null ? "" : c.size3x1 == 0 ? "" : "3x1"}
                        <br />
                        {c.size3x1 == null
                          ? ""
                          : c.size3x1 == 0
                          ? ""
                          : c.size3x1}
                      </span>
                    ) : (
                      ""
                    )}
                  </GridContainer>
                  {/* <span className={classes.listDescription}>
                      {c && c.name ? c.name + ", " : ""}{" "}
                      {c && c.mycomment ? c.mycomment : ""}
                    </span> */}
                </span>,
                <span
                  className={classes.headRight + " " + classes.listNumber}
                  key={c.id}
                >
                  {c.cartQuantity}
                </span>,
                <span
                  className={classes.headRight + " " + classes.listNumber}
                  key={c.id}
                >
                  {c.sizeTotal}
                </span>,
                <span
                  className={classes.headRight + " " + classes.listNumber}
                  key={c.id}
                >
                  ₹{" "}
                  {c.gstCash === true
                    ? c.taxInclude.toFixed(2)
                    : c.cashPrice.toFixed(2)}
                </span>,
                <span
                  className={classes.headRight + " " + classes.listNumber}
                  key={c.id}
                >
                  ₹ {Math.round(c.amount)}
                </span>,
                <Tooltip
                  id="close1"
                  title="Cart Remove"
                  placement="left"
                  classes={{ tooltip: classe.tooltip }}
                  key={c.id}
                >
                  <Button
                    link
                    className={classe.actionButton}
                    //   onClick={() => handleRemove(c)}
                  >
                    <Close style={{ color: "red" }} />
                  </Button>
                </Tooltip>,
              ]),
              [
                "",
                <span className={classes.listNumber} key={1}>
                  Carton Charges
                  <br />
                  Grand Total
                </span>,
                <span className={classes.listNumber} key={2}>
                  <br />
                  Advance - ₹{" "}
                  {Math.round(
                    viewCart.reduce(
                      (a, b) =>
                        a +
                        b.metalGram *
                          b.setsPcs *
                          b.box *
                          b.sizeTotal *
                          (b.rawPrice + b.rawDifferent),
                      0
                    )
                  ) + "/-"}
                  {/* {viewCart.map((c) => {
                      totalCal =
                        totalCal +
                        c.metalGram *
                          c.sets.pcs *
                          c.box.box *
                          c.total *
                          c.metal.price;
                      return c.mixmmList && c.mixmmList
                        ? c.mixmmList.map((m) => {
                            totalCalMix =
                              totalCalMix +
                              m.metalOption.metalgram *
                                m.sets.pcs *
                                m.metal.price *
                                c.box.box *
                                c.total;
                            totalSum = totalCalMix + totalCal;
                            return "";
                          })
                        : "";
                    })}
                    Advance - ₹
                    {Math.round((totalSum = totalCalMix + totalCal)) + "/-"} */}
                </span>,
                <span key={3}>
                  <br />
                  <span
                    className={classes.headRight + " " + classes.listNumber}
                  >
                    CTN-
                    {viewCart.reduce(
                      (a, b) => parseInt(a) + parseInt(b.cartQuantity),
                      0
                    )}
                  </span>
                </span>,
                <span key={3}>
                  <br />
                  <span
                    className={classes.headRight + " " + classes.listNumber}
                  >
                    Box-
                    {viewCart && viewCart.reduce((a, b) => a + b.sizeTotal, 0)}
                  </span>
                </span>,
                "",
                <span key={4}>
                  <span
                    className={classes.headRight + " " + classes.listNumber}
                  >
                    ₹{" "}
                    {viewCart.reduce((a, b) => a + b.cartPackCharge, 0) + "/-"}
                  </span>
                  <br />
                  <span
                    className={classes.headRight + " " + classes.listNumber}
                  >
                    ₹{" "}
                    {Math.round(
                      viewCart.reduce(
                        (a, b) => a + b.amount + b.cartPackCharge,
                        0
                      )
                    ) + "/-"}
                  </span>
                </span>,
                "",
              ],
            ]}
          />
          <hr style={{ borderBottom: "1px solid rgba(0,0,0,.1)" }} />
          <GridItem className={classes.textCenter}>
            <h5>
              <strong>Terms & Condition</strong>
            </h5>
          </GridItem>
          <hr style={{ borderBottom: "1px solid rgba(0,0,0,.1)" }} />
          <GridItem className={classes.condition}>
            <ol>
              <li>
                Payment : Advance{" "}
                <span className={classes.termDescription}>
                  {/* Rs. {Math.round(totalSum)}/- */}
                  Rs. ₹{" "}
                  {Math.round(
                    viewCart.reduce(
                      (a, b) =>
                        a +
                        b.metalGram *
                          b.setsPcs *
                          b.box *
                          b.sizeTotal *
                          (b.rawPrice + b.rawDifferent),
                      0
                    )
                  ) + "/-"}
                </span>{" "}
                of total amount along with purchase order 20% when dispatchof
                1st shipment and Remaining 15% will be payable on confirmation
                of completion of goods ordered,
              </li>
              <li>
                Delivery: 50% of the total goods will be delivered in the first
                shipment. Remaining 50% will be delivered on the receipt of
                total outstanding amount of whole purchase order (I.E remaining
                15%),
              </li>
              <li>Packing and Transportation charges will be extra,</li>
              <li>Insurance covered, if paid 3% of the total purchase,</li>
              <li>Proposal is valid for 10 days from,</li>
            </ol>
          </GridItem>
          <div className={classes.brand}>
            <Button
              className={classes.print}
              color="rose"
              round
              href={`/view-cart-print?client=${state && state.cId}&ad=${
                state && state.rId
              }`}
              // onClick={() =>
              //   history.push({
              //     pathname: `/view-cart-print`,
              //     state: {reg,clientid}
              //   })
              // }
              // onClick={() => window.print()}
            >
              Go Print
            </Button>
            <span style={{ marginLeft: "10px" }}>
              <Button
                className={classes.print}
                color="rose"
                round
                href={`/cart-shipping-address?client=${state && state.rId}`}
                // onClick={() =>
                //   history.push({
                //     pathname: `/cart-shipping-address`,
                //     state: reg,
                //   })
                // }
              >
                Proceed Order
              </Button>
            </span>
          </div>
        </div>
        {/* </CardBody>
                </Card> */}
      </GridContainer>
    </div>
  );
}
