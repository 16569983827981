import React, { useEffect } from "react";
import axios from "axios";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
// core components
import Favorite from "@material-ui/icons/Favorite";
import LinkIcon from "@material-ui/icons/Link";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { localApi, credentials } from "utils/imgApi";
import Slide from "@material-ui/core/Slide";
import { IoLogoWhatsapp } from "react-icons/io";
import { ImMobile } from "react-icons/im";

import stylesPrint from "assets/jss/material-dashboard-pro-react/views/crmUserListPrintStyle";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import styleDailog from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import styCheckbox from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);
const useStyleDailog = makeStyles(styleDailog);
const useStylePrint = makeStyles(stylesPrint);
const useSty = makeStyles(styCheckbox);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function ContactList() {
  const [dataOrders, setDataOrder] = React.useState([]);
  const [makingCheckboxMaking, setMakingCheckboxMaking] = React.useState("");
  const [checkBoxMaking, setCheckBoxmaking] = React.useState([]);
  const [makChecked, setMakChecked] = React.useState("");

  const classes = useStyles();
  const classCheck = useSty();
  const classTable = useStylePrint();
  const classeDailog = useStyleDailog();
  const history = useHistory();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const handleCheckBoxMaking = (event, or) => {
    // setCheckBoxmaking(e.target.checked);
    // const { name, checked } = event.target;

    setCheckBoxmaking((prev) => {
      if (prev.includes(or)) {
        return prev.filter((x) => x !== or);
      } else {
        return [...prev, or];
      }
    });

    setMakChecked((prev) => {
      if (prev.includes(or?.id)) {
        return prev.filter((x) => x !== or?.id);
      } else {
        return [...prev, or?.id];
      }
    });
  };

  async function getSets() {
    const api =
      localApi + `/contact/list/byCrmReferenceIdAndActiveFalse/${userObj.id}`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await result.json();
    // console.log(getResult);
    setDataOrder(
      getResult.map((or, key) => {
        return {
          sino: key + 1,
          firstName:
            or && or.company ? or.company : or.firstname + "" + or.lastname,
          mobile: (
            <div>
              <a href={`tel: ${or.mobile}`}>
                <ImMobile color="black" /> {or.mobile}
              </a>
            </div>
          ),
          whatsapp: (
            <div>
              {or.whatsapp != null ? (
                <a
                  href={`https://api.whatsapp.com/send/?phone=${or.whatsapp}&text=Hello&app_absent=0`}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <IoLogoWhatsapp color="green" /> {or.whatsapp}
                </a>
              ) : (
                ""
              )}
            </div>
          ),
          email: or.email,
          type: or.type,
          city: or.city,
          actions: (
            <div className="actions-right">
              {or.url != null ? (
                <Button
                  justIcon
                  round
                  simple
                  // onClick={() => {}}
                  target={"_blank"}
                  rel="noreferrer"
                  href={or.url}
                  color="info"
                  className="like"
                >
                  <LinkIcon />
                </Button>
              ) : (
                ""
              )}{" "}
              <FormControlLabel
                key={or.id}
                control={
                  <Checkbox
                    tabIndex={-1}
                    // checked={checkBoxMaking.includes(or)}
                    name={or}
                    onChange={(e) => {
                      handleCheckBoxMaking(e, or);
                    }}
                    checkedIcon={<Check className={classCheck.checkedIcon} />}
                    icon={<Check className={classCheck.uncheckedIcon} />}
                    classes={{
                      checked: classCheck.checkedRose,
                      root: classCheck.checkRoot,
                    }}
                  />
                }
                classes={{
                  label:
                    classCheck.label +
                    (makingCheckboxMaking === "error"
                      ? " " + classCheck.labelError
                      : ""),
                }}
                // label={making.description + "> " + making.price}
              />
              {/* <Button justIcon round simple color="danger" className="remove">
                <VisibilityIcon />
              </Button>{" "} */}
            </div>
          ),
        };
      })
    );
  }

  useEffect(() => {
    getSets();
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Contact Pending List</h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              columns={[
                {
                  Header: "SI.No.",
                  accessor: "sino",
                },
                {
                  Header: "Co. & Full Name",
                  accessor: "firstName",
                },
                {
                  Header: "Mobile",
                  accessor: "mobile",
                },
                {
                  Header: "Whatsapp",
                  accessor: "whatsapp",
                },
                {
                  Header: "Email",
                  accessor: "email",
                },
                {
                  Header: "Type",
                  accessor: "type",
                },
                {
                  Header: "City",
                  accessor: "city",
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                },
              ]}
              data={dataOrders}
            />
          </CardBody>
        </Card>
      </GridItem>
      <div>
        {dataOrders.length != "0" ? (
          <Button
            color="rose"
            round
            onClick={() =>
              history.push({
                pathname: `/view-contactList-print`,
                state: checkBoxMaking,
              })
            }
          >
            print
          </Button>
        ) : (
          ""
        )}
      </div>
    </GridContainer>
  );
}
