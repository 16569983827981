import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Datetime from "react-datetime";
import { localApi, credentials } from "utils/imgApi";
import { produce } from "immer";
import axios from "axios";
import Selects from "react-select";
import Table from "components/Table/Tables.js";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PersonPinCircleIcon from "@material-ui/icons/PersonPinCircle";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import SweetAlert from "react-bootstrap-sweetalert";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import style from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styleCheck from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import styDrop from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import stylesTable from "assets/jss/material-dashboard-pro-react/views/viewCartStyle";
import styleDailog from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(style);
const useStyleCheck = makeStyles(styleCheck);
const useStyl = makeStyles(styDrop);
const useStyleTable = makeStyles(stylesTable);
const useStyleDailog = makeStyles(styleDailog);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function AllocationMultiReport() {
  const [itemClient, setItemClient] = React.useState([]);
  const [callSub, setCallSub] = React.useState([]);
  const [simpleSelectExecutive, setSimpleSelectExecutive] = React.useState("");
  const [simpleSelectAllocation, setSimpleSelectAllocation] = React.useState(
    ""
  );
  const [contactDetails, setContactDetails] = React.useState("");
  const [simpleSelectCategory, setSimpleSelectCategory] = React.useState("");
  const [
    simpleSelectCallSubStatus,
    setSimpleSelectCallSubStatus,
  ] = React.useState("");
  const [simpleSelectNextAction, setSimpleSelectNextAction] = React.useState(
    ""
  );
  const [AfterDate, setAfterDate] = React.useState("");
  const [AfterTime, setAfterTime] = React.useState("");
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [makingCheckboxMaking, setMakingCheckboxMaking] = React.useState("");
  const [checkBoxMaking, setCheckBoxmaking] = React.useState([]);

  const [expandedRows, setExpandedRows] = React.useState([]);
  const [expandState, setExpandState] = React.useState({});

  const [noticeModal, setNoticeModal] = React.useState(false);
  const [callListId, setCallListId] = React.useState("");
  const [callListName, setCallListName] = React.useState("");
  const [callListRemark, setCallListRemark] = React.useState("");
  const [callListSuggest, setCallListSuggest] = React.useState("");

  const classes = useStyles();
  const classSweet = useStyle();
  const classCheck = useStyleCheck();
  const classe = useStyl();
  const classTable = useStyleTable();
  const classeDailog = useStyleDailog();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  function formatTime(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  async function getCrmData() {
    const api = localApi + `/register/list/byAdminId/${userObj.admin.id}`;
    const results = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await results.json();
    setItemClient(getResult.filter((r) => r.type === "Crm"));
  }

  useEffect(() => {
    getCrmData();
  }, []);

  const handleSimpleCategory = async (e) => {
    const { value } = e.target;
    setSimpleSelectCategory(value);
    const api =
      localApi +
      `/callallocation/list/byCrmReferenceIdAndSubStatus/${userObj.id}/${value}`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await result.json();
    // console.log(getResult);
    setCallSub(getResult);
  };

  const openAPi = [
    { id: 1, callSubStatus: "ACTIVE" },
    { id: 2, callSubStatus: "HOLD" },
    { id: 3, callSubStatus: "PENDING" },
    { id: 4, callSubStatus: "UNDER PROCESS" },
    { id: 5, callSubStatus: "PAYMENT" },
  ];

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  const mystyle = {
    paddingLeft: 12,
    flex: 1,
  };

  const select = {
    width: "100%",
    maxWidth: 600,
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
    }),
    menu: (state) => ({
      color: "#d81b60",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  return (
    <div>
      <GridContainer justify="center" alignItems="center">
        {submitted ? (
          <SweetAlert
            type={alertType}
            style={{ display: "block", marginTop: "-100px" }}
            title={alertMsg}
            onConfirm={() => hideAlert()}
            confirmBtnCssClass={classSweet.button + " " + classSweet.success}
          />
        ) : null}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <PersonPinCircleIcon />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Allocation Multi Report</small>
              </h3>
            </CardHeader>
            <CardBody>
              <form>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <FormControl fullWidth className={classe.selectFormControl}>
                      <InputLabel
                        htmlFor="simple-select"
                        className={classe.selectLabel}
                      >
                        Select Sub Status...
                      </InputLabel>

                      <Select
                        MenuProps={{
                          className: classe.selectMenu,
                        }}
                        classes={{
                          select: classe.select,
                        }}
                        value={simpleSelectCategory}
                        onChange={handleSimpleCategory}
                        inputProps={{
                          name: "simpleSelectCategory",
                          id: "simple-select-category",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classe.selectMenuItem,
                          }}
                        >
                          Select Sub Status
                        </MenuItem>
                        {openAPi.map((t) => (
                          <MenuItem
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                            key={t.id}
                            value={t.callSubStatus}
                          >
                            {t.callSubStatus}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {callSub.length != "0" ? (
        <GridContainer>
          {simpleSelectCategory ? (
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <div className={classTable.container}>
                  <h3 className={classTable.cardTitle}>
                    Call Allocation Report
                  </h3>
                  <Table
                    hover
                    tableHead={[
                      <span className={classTable.tableHead} key="sr">
                        Sr.No.
                      </span>,
                      <span
                        className={
                          classTable.tableHead + " " + classTable.headLeft
                        }
                        key="dateTime"
                      >
                        DateTime
                      </span>,
                      <span
                        className={
                          classTable.tableHead + " " + classTable.headLeft
                        }
                        key="contact"
                      >
                        Contact
                      </span>,
                      <span
                        className={
                          classTable.tableHead + " " + classTable.headLeft
                        }
                        key="executive"
                      >
                        Executive
                      </span>,
                      <span
                        className={
                          classTable.tableHead + " " + classTable.headLeft
                        }
                        key="call"
                      >
                        CAll
                      </span>,
                      <span
                        className={
                          classTable.tableHead + " " + classTable.headLeft
                        }
                        key="subCall"
                      >
                        SubCall
                      </span>,
                      <span
                        className={
                          classTable.tableHead + " " + classTable.headLeft
                        }
                        key="action"
                      >
                        Action
                      </span>,
                      <span
                        className={
                          classTable.tableHead + " " + classTable.headLeft
                        }
                        key="aDate"
                      >
                        A Date
                      </span>,
                      <span
                        className={
                          classTable.tableHead + " " + classTable.headLeft
                        }
                        key="aTime"
                      >
                        A Time
                      </span>,
                    ]}
                    tableData={
                      callSub &&
                      callSub.map((c, index) => [
                        [
                          <span className={classTable.OrderlistDescription}>
                            {/* {index + 1} */}
                            {c.id}
                          </span>,
                          <span
                            className={
                              classTable.headLeft + " " + classTable.listNumber
                            }
                          >
                            {c.createdate}
                          </span>,
                          <span
                            className={
                              classTable.headLeft + " " + classTable.listNumber
                            }
                          >
                            {c.firstname + " " + c.lastname}
                          </span>,
                          <span
                            className={
                              classTable.headLeft + " " + classTable.listNumber
                            }
                          >
                            {c.receivedBy}
                          </span>,
                          <span
                            className={
                              classTable.headLeft + " " + classTable.listNumber
                            }
                          >
                            {c.callStatus}
                          </span>,
                          <span
                            className={
                              classTable.headLeft + " " + classTable.listNumber
                            }
                          >
                            {c.callSubStatus}
                          </span>,
                          <span
                            className={
                              classTable.headLeft + " " + classTable.listNumber
                            }
                          >
                            {c.action}
                          </span>,
                          <span
                            className={
                              classTable.headLeft + " " + classTable.listNumber
                            }
                          >
                            {c.afterdate}
                          </span>,
                          <span
                            className={
                              classTable.headLeft + " " + classTable.listNumber
                            }
                          >
                            {c.aftertime}
                          </span>,
                          <span>
                            <Button
                              justIcon
                              round
                              simple
                              onClick={() => {
                                setNoticeModal(true);
                                setCallListId(c.id);
                                setCallListName(c.firstname + " " + c.lastname);
                                setCallListRemark(c.callReceiptRemark);
                                setCallListSuggest(c.suggestedActionRemark);
                              }}
                              color="danger"
                              className="remove"
                            >
                              <VisibilityIcon />
                            </Button>{" "}
                          </span>,
                        ],
                      ])
                    }
                  />
                </div>
              </Card>
            </GridItem>
          ) : (
            ""
          )}
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12} className={classeDailog.center}>
              <Dialog
                classes={{
                  root: classeDailog.center + " " + classeDailog.modalRoot,
                  paper: classeDailog.modal,
                }}
                open={noticeModal}
                TransitionComponent={Transition}
                keepMounted
                // onClose={() => setNoticeModal(false)}
                aria-labelledby="notice-modal-slide-title"
                aria-describedby="notice-modal-slide-description"
              >
                <DialogTitle
                  id="notice-modal-slide-title"
                  disableTypography
                  className={classeDailog.modalHeader}
                >
                  <Button
                    justIcon
                    className={classeDailog.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    color="transparent"
                    onClick={() => setNoticeModal(false)}
                  >
                    <Close className={classeDailog.modalClose} />
                  </Button>
                </DialogTitle>
                <DialogTitle
                  id="notice-modal-slide-title"
                  disableTypography
                  className={classeDailog.modalHeader}
                >
                  <h4
                    className={classeDailog.modalTitle}
                    style={{ fontWeight: "bold" }}
                  >
                    Complain No.: {callListId}
                  </h4>
                  <h4
                    className={classeDailog.modalTitle}
                    style={{ fontWeight: "bold" }}
                  >
                    Contact Name: {callListName}
                  </h4>
                </DialogTitle>
                <DialogContent
                  id="notice-modal-slide-description"
                  className={classeDailog.modalBody}
                >
                  <GridContainer
                    style={{ marginLeft: "0px", marginRight: "400px" }}
                  >
                    <GridItem xs={12} sm={12} md={12}>
                      <span
                        style={{ textDecoration: "underline" }}
                        className={classeDailog.paymentOrder}
                      >
                        Call Receipt Remarks
                      </span>
                      <br />
                      <span className={classeDailog.callDesc}>
                        {callListRemark}
                      </span>
                    </GridItem>
                  </GridContainer>
                  <hr />
                  <GridContainer
                    style={{ marginLeft: "0px", marginRight: "400px" }}
                  >
                    <GridItem xs={12} sm={12} md={12}>
                      <span
                        style={{ textDecoration: "underline" }}
                        className={classeDailog.paymentOrder}
                      >
                        Suggested Action Remaks
                      </span>
                      <br />
                      <span className={classeDailog.callDesc}>
                        {callListSuggest}
                      </span>
                    </GridItem>
                  </GridContainer>
                </DialogContent>
                <DialogActions
                  className={
                    classeDailog.modalFooter +
                    " " +
                    classeDailog.modalFooterCenter
                  }
                >
                  {/* <Button color="rose" round onClick={() => handleOrderUpdate()}>
                  update
                </Button> */}
                </DialogActions>
              </Dialog>
            </GridItem>
          </GridContainer>
        </GridContainer>
      ) : (
        ""
      )}
    </div>
  );
}
