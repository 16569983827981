import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Select from "react-select";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { headApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import { localApi, credentials } from "utils/imgApi";
import { useHistory } from "react-router-dom";
import Slide from "@material-ui/core/Slide";
import Accordion from "components/Accordion/Accordion.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomInput from "components/CustomInput/CustomInput.js";

// material-ui icons
import Tooltip from "@material-ui/core/Tooltip";
import Close from "@material-ui/icons/Close";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import Table from "components/Table/Tables.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";
import Instruction from "components/Instruction/Instruction.js";
import SweetAlert from "react-bootstrap-sweetalert";

import styles from "assets/jss/material-dashboard-pro-react/views/viewCartStyle";
import styDrop from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import style from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styleDailog from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
// import styles from "assets/jss/material-dashboard-pro-react/views/orderViewPrintStyle";

import noticeModal1 from "assets/img/card-1.jpeg";
import noticeModal2 from "assets/img/card-2.jpeg";
// import logo from "assets/img/apple-admin-icon.png";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(styDrop);
const useStyl = makeStyles(style);
const useStyleDailog = makeStyles(styleDailog);
// const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function OrderHistory() {
  const [addCart, setAddCart] = React.useState([]);
  const [client, setClient] = React.useState([]);
  const [clientId, setClientId] = React.useState("");
  const [clientid, setClientid] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [submitteds, setSubmitteds] = React.useState(false);
  const [submittedConfirm, setSubmittedConfirm] = React.useState(false);

  const [cartId, setCartId] = React.useState();
  const [alertMsg, alertMsgOps] = React.useState("");
  const [alertType, alertTypeOps] = React.useState("");
  const [noticeModal, setNoticeModal] = React.useState(false);
  const [arrSortList, setArrSortList] = React.useState("");

  const [orderId, setOrderId] = React.useState("");
  const [invoiceNumber, setInvoiceNumber] = React.useState("");
  const [Date, setDate] = React.useState("");
  const [reverseCharge, setReverseCharge] = React.useState("");
  const [grRrNumber, setGrRrNumber] = React.useState("");
  const [stationName, setStation] = React.useState("");
  const [eWayBillNumber, setEWayBillNumber] = React.useState("");

  const [expandedRows, setExpandedRows] = React.useState([]);
  const [expandState, setExpandState] = React.useState({});

  const classes = useStyles();
  const classe = useStyle();
  const history = useHistory();
  const classeAlert = useStyl();
  const classeDailog = useStyleDailog();
  const classTable = useStyles();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  function arrSorts(arr) {
    arr.sort((a, b) => a - b);
    arr.reverse();
    return arr;
  }

  function order(a, b) {
    return a < b ? -1 : a > b ? 1 : 0;
  }

  async function getClient() {
    const regCrm =
      localApi +
      `/register/list/byAdminIdAndCrmReferenceId/${userObj.admin.id}/${userObj.id}`;
    const resultRegCrm = await fetch(regCrm, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResultCrm = await resultRegCrm.json();
    const getRegCrmId = getResultCrm.map((m) => {
      return m.id;
    });

    const api = localApi + `/clientGroup/list/byAdminId/${userObj.admin.id}`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await result.json();
    setClient(getResult.filter((f) => getRegCrmId.includes(f.regId)));
    // console.log(getResult.user);
  }

  useEffect(() => {
    getClient();
  }, []);

  const handleDatePicker = (Date) => {
    setDate(
      Date._d.getFullYear() +
        "-" +
        (Date._d.getMonth() + 1) +
        "-" +
        Date._d.getDate()
    );
  };

  function merge(...arrays) {
    let newArray = [];
    for (let i = 0; i < arrays.length; i++) {
      newArray.push(...arrays[i]);
    }
    return newArray;
  }

  const handleBoots = (bootselect) => {
    if (bootselect.value) {
      let newCut = bootselect.value.split("-");
      const ids = client.find((f) => f.mobile == newCut[1]);
      setClientid(ids);
      // console.log(ids);
      axios
        .get(
          localApi +
            `/orders/list/byAdminAndClientIdwithzero/${userObj.admin.id}/${ids.id}`, {headers: {
              Authorization: `Basic ${credentials}`,
            }}
        )
        .then((res) => {
          console.log(res.data);
          if (res.data.length === 0) {
            setSubmitted(true);
            alertMsgOps("Data Not Found");
          } else {
            setAddCart(res.data);
            // console.log(res.data);
          }
        });
    }
    setClientId(bootselect);
  };

  const handleEpandRow = (event, userId) => {
    const currentExpandedRows = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(userId);

    let obj = {};
    isRowExpanded ? (obj[userId] = false) : (obj[userId] = true);
    setExpandState(obj);

    // If the row is expanded, we are here to hide it. Hence remove
    // it from the state variable. Otherwise add to it.
    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter((id) => id !== userId)
      : currentExpandedRows.concat(userId);

    setExpandedRows(newExpandedRows);

    const orId = addCart.find((f) => f.id === userId);
    // console.log(orId);
    // const oId = orId.payment.length - 1;
    const oId = orId.payment.map((p) => {
      return p.id;
    });
    // let l = oId.pop();
    // console.log(oId.sort((a,b) => b - a)[0]);
    setArrSortList(oId.sort((a, b) => b - a)[0]);
  };

  const handleRemove = (p) => {
    setSubmitteds(true);
    setCartId(p);
  };

  const handleConfirm = () => {
    // console.log("oooo", cartId);

    fetch(localApi + `/payment/delete/byPaymentId/${cartId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        if (res.message === "Deleted Successfully !!!") {
          alertTypeOps("success");
          alertMsgOps("Deleted Succesfully !!!");
          setSubmittedConfirm(true);
        }
      });
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
    }),
    menu: (state) => ({
      color: "#d81b60",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  const hideAlerts = () => {
    setSubmitteds(false);
    setSubmittedConfirm(false);
    window.location.reload(false);
  };

  return (
    <div className={classes.productPage}>
      <GridContainer justify="center" alignItems="center">
        {/* <Card>
                    <CardBody> */}
        <GridContainer
          justify="center"
          alignItems="center"
          className={classes.selects}
        >
          <GridItem xs={12} sm={12} md={6} style={{ marginBottom: "3%" }}>
            <label>Select Client ID</label>
            <Select
              styles={customStyles}
              className={classes.select}
              placeholder="Select Client Id"
              value={clientId}
              onChange={handleBoots}
              options={client.map((c) => {
                return {
                  label:
                    c && c.company
                      ? c.company + "-" + c.mobile
                      : c.firstname + " " + c.lastname + "-" + c.mobile,
                  value:
                    c && c.company
                      ? c.company + "-" + c.mobile
                      : c.firstname + " " + c.lastname + "-" + c.mobile,
                };
              })}
            />
          </GridItem>
        </GridContainer>

         {submitted ? (
          <SweetAlert
            type={alertType}
            style={{ display: "block", marginTop: "-100px" }}
            title={alertMsg}
            onConfirm={() => hideAlert()}
            confirmBtnCssClass={classeAlert.button + " " + classeAlert.success}
          />
        ) : null}
        {submitteds ? (
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "100px" }}
            title="Are you sure?"
            onConfirm={handleConfirm}
            onCancel={() => hideAlerts()}
            confirmBtnCssClass={classeAlert.button + " " + classeAlert.success}
            cancelBtnCssClass={classeAlert.button + " " + classeAlert.danger}
            confirmBtnText="Yes, delete it!"
            cancelBtnText="Cancel"
            showCancel
          />
        ) : null}

        {submittedConfirm ? (
          <SweetAlert
            success
            style={{ display: "block", marginTop: "100px" }}
            title="Deleted!"
            onConfirm={() => hideAlerts()}
            onCancel={() => hideAlerts()}
            confirmBtnCssClass={classeAlert.button + " " + classeAlert.success}
          >
            Your item has been deleted.
          </SweetAlert>
        ) : null}
      </GridContainer>

      <GridContainer>
        {clientId ? (
          <Card>
            <div className={classes.container}>
              <h3 className={classes.cardTitle}>Order History List</h3>
              <Table
                tableHead={[
                  <span className={classes.tableHead} key="sr">
                    Sr.No.
                  </span>,
                  <span
                    className={classes.tableHead + " " + classes.headRight}
                    key="des"
                  >
                    Order Id
                  </span>,
                  <span
                    className={classes.tableHead + " " + classes.headRight}
                    key="ctn"
                  >
                    Date
                  </span>,
                  <span
                    className={classes.tableHead + " " + classes.headRight}
                    key="ctn"
                  >
                    Boxes/Dozan
                  </span>,
                  <span
                    className={classes.tableHead + " " + classes.headRight}
                    key="qty"
                  >
                    Amount
                  </span>,
                  <span
                    className={classes.tableHead + " " + classes.headRight}
                    key="qty"
                  >
                    More
                  </span>,
                ]}
                tableData={
                  addCart &&
                  addCart.map((c, index) => [
                    [
                      <span className={classes.OrderlistDescription}>
                        {index + 1}
                      </span>,
                      <span
                        className={classes.headRight + " " + classes.listNumber}
                      >
                        {c.id}
                      </span>,
                      <span
                        className={classes.headRight + " " + classes.listNumber}
                      >
                        {c.createDate.slice(0, -19)}
                      </span>,
                      <span
                        className={classes.headRight + " " + classes.listNumber}
                      >
                        {c.totalBox + " - " + "Units"}
                      </span>,
                      <span
                        className={classes.headRight + " " + classes.listNumber}
                      >
                        ₹ {Math.round(c.amount) + "/-"}
                      </span>,
                      <span>
                        <Button
                          link
                          className={
                            classes.headRight + " " + classes.actionButton
                          }
                          onClick={(event) => handleEpandRow(event, c.id)}
                        >
                          {expandState[c.id] ? (
                            <ArrowUpwardIcon style={{ color: "red" }} />
                          ) : (
                            <ArrowDownwardIcon style={{ color: "red" }} />
                          )}
                        </Button>
                      </span>,
                    ],
                    [
                      expandedRows.includes(c.id) ? (
                        <span>
                          {c.payment.map((p, index) => (
                            <span
                              style={{
                                marginTop: "20px",
                                marginBottom: "20px",
                              }}
                              key={p.id}
                            >
                              {index + 1}
                              <br />
                            </span>
                          ))}
                        </span>
                      ) : null,
                      expandedRows.includes(c.id) ? (
                        <span className={classes.headRight}>
                          {c.payment.map((p) => (
                            <span
                              style={{
                                marginTop: "20px",
                                marginBottom: "20px",
                              }}
                              key={p.id}
                            >
                              {p.id}
                              <br />
                            </span>
                          ))}
                        </span>
                      ) : null,
                      expandedRows.includes(c.id) ? (
                        <span className={classes.headRight}>
                          {c.payment.map((p) => (
                            <span
                              style={{
                                marginTop: "20px",
                                marginBottom: "20px",
                              }}
                              key={p.id}
                            >
                              {p.createDate.slice(0, -19)}
                              <br />
                            </span>
                          ))}
                        </span>
                      ) : null,
                      "",
                      expandedRows.includes(c.id) ? (
                        <span className={classes.headRight}>
                          {c.payment.map((p) => (
                            <span
                              style={{
                                marginTop: "20px",
                                marginBottom: "20px",
                              }}
                              key={p.id}
                            >
                              {p.received}
                              <br />
                            </span>
                          ))}
                        </span>
                      ) : null,
                      expandedRows.includes(c.id) ? (
                        <span>
                          {c.payment.map((p, index) => {
                            // const ap = index;
                            // console.log(ap);
                            // console.log(arrSortList);
                            return (
                              <span key={p.id}>
                                {p.id === arrSortList ? (
                                  <Tooltip
                                    id="close1"
                                    title="Payment Remove"
                                    placement="left"
                                    classes={{ tooltip: classes.tooltip }}
                                    key={c.id}
                                  >
                                    <Button
                                      link
                                      className={classes.actionButton}
                                      onClick={() => handleRemove(p.id)}
                                    >
                                      <Close style={{ color: "red" }} />
                                    </Button>
                                  </Tooltip>
                                ) : (
                                  ""
                                )}
                                <br />
                              </span>
                            );
                          })}
                        </span>
                      ) : null,
                      // "",
                    ],
                  ])
                }
              />
            </div>
          </Card>
        ) : (
          ""
        )}
      </GridContainer>
    </div>
  );
}
