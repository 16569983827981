import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { localApi, credentials } from "utils/imgApi";
import { useHistory, useLocation } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Card } from "@material-ui/core";
import Table from "components/Table/Table.js";
import { ToWords } from "to-words";
import dayjs from "dayjs";

import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import Close from "@material-ui/icons/Close";
import { IoLogoWhatsapp } from "react-icons/io";
import { ImMobile } from "react-icons/im";
import { MdEmail } from "react-icons/md";
import { TiPhoneOutline } from "react-icons/ti";
import SweetAlert from "react-bootstrap-sweetalert";
import style from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/orderViewPrintStyle";
import logo from "assets/img/apple-admin-icon.png";

const useStyles = makeStyles(styles);
const useStyl = makeStyles(style);

export default function LabourClientBillViewPrintWithoutSize() {
  const [viewCart, setViewCart] = React.useState([]);
  const [adminAddress, setAdminAddress] = React.useState([]);
  const [regAddress1, setRegAddress1] = React.useState([]);
  const [regAddress2, setRegAddress2] = React.useState([]);
  const [termsClient, setTermsClient] = React.useState([]);
  const [terms, setTerms] = React.useState([]);
  const [ordList, setOrdList] = React.useState("");
  const [branch, setBranch] = React.useState("");
  const [cartLength, setCartLength] = React.useState("");

  const [cartId, setCartId] = React.useState();
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [submitteds, setSubmitteds] = React.useState(false);
  const [submittedConfirm, setSubmittedConfirm] = React.useState(false);

  const [invoiceDate, setInvoiceDate] = React.useState("");
  const [ordersDate, setOrdersDate] = React.useState("");

  const [uniPer, setUniPer] = React.useState([]);
  const [newTaxable, setNewTaxable] = React.useState([]);
  const [newIgst, setNewIgst] = React.useState([]);
  const [newCgst, setNewCgst] = React.useState([]);
  const [newSgst, setNewSgst] = React.useState([]);
  const [newTotalTax, setNewTotalTax] = React.useState([]);
  const [finalAmt, setFinalAmt] = React.useState();
  const [tin, setTin] = React.useState("");

  const classTable = useStyles();
  const classSweet = useStyl();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const { search } = useLocation();
  const client = new URLSearchParams(search).get("client");
  const order = new URLSearchParams(search).get("order");

  const termsDefault = [
    {
      id: 1,
      discription: "Goods once sold will not be taken back.",
      type: "Tax Invoice",
    },
    {
      id: 2,
      discription:
        "Interest @ 18% p.a. will be charged if the payment is not made within the stipulated time.",
      type: "Tax Invoice",
    },
    {
      id: 3,
      discription: "Subject to 'Maharashtra' Jurisdiction only.",
      type: "Tax Invoice",
    },
  ];

  async function getProd() {
    const get = localApi + `/adminAddress/list/byAdminId/${userObj.admin.id}`;
    const results = await fetch(get, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const res = await results.json();
    setAdminAddress(
      res &&
      res.filter(
        (a) => a.addressType === "GSTIN ADDRESS" && a.active === true
      )
    );
    // console.log(res);
  }

  async function getOrderList() {
    const getOrder =
      localApi +
      `/orders/list/byAdminAndOrdersId/${userObj.admin.id}/${order}`;
    const resOrder = await fetch(getOrder, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const resultOrder = await resOrder.json();
    // console.log(resultOrder);
    setOrdList(resultOrder && resultOrder);
    // console.log(resultOrder.dateOfInvoice);
    setInvoiceDate(resultOrder.dateOfInvoice);
    const orDate = dayjs(resultOrder.createDate.slice(0, -19)).format("DD-MM-YYYY");
    const inDate = dayjs(resultOrder.dateOfInvoice).format("DD-MM-YYYY");
    setInvoiceDate(inDate);
    const gstAddress = resultOrder.deliveryAddress.filter((f) =>
      f && f.addressType === "GSTIN ADDRESS"
        ? f.addressType === "GSTIN ADDRESS"
        : f.addressType === "AADHAR ADDRESS"
          ? f.addressType === "AADHAR ADDRESS"
          : f.addressType === "OTHER ADDRESS"
            ? f.addressType === "OTHER ADDRESS"
            : ""
    );
    const shippingAddress = resultOrder.deliveryAddress.filter(
      (f) => f.addressType != "GSTIN ADDRESS"
    );
    setRegAddress1(gstAddress[0]);
    setTin(gstAddress[0].gstIn != null ? gstAddress[0].gstIn.slice(0, -13) : "");
    setRegAddress2(shippingAddress[0]);
    setBranch(resultOrder.transbranch);

    // const multiCartId = resultOrder.ordersCart.map((i) => {
    //   return i.cartId;
    // });

    const obj = resultOrder.multiCartId;

    const getCart =
      localApi + `/cart/list/byAdminId/${userObj && userObj.admin.id}`;
    const resCart = await fetch(getCart, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const resultCart = await resCart.json();
    const ordersCart = resultCart.filter(
      (f) => f.active === true && obj.includes(f.id)
    );

    setCartLength(obj.length);
    setViewCart(ordersCart);
    // console.log(ordersCart);

    // var converter = require("number-to-words");
    const fAmount = Math.round(
      ordersCart &&
      ordersCart.reduce(
        (a, b) => a + (b.amount + b.cartPackCharge * b.cartQuantity),
        0
      )
    );

    const toWords = new ToWords();
    let words = toWords.convert(fAmount);
    // console.log(words);
    setFinalAmt(words && words);


    // const tax = ordersCart && ordersCart.map((m) => m.taxsumary);
    // const taaxId = tax.map((m) => {
    //   return m.taxId;
    // });
    // console.log(taaxId);

    const uniquePer = [];
    ordersCart &&
      ordersCart.map((x) =>
        uniquePer.filter((a) => a.taxType == x.taxType).length > 0
          ? null
          : uniquePer.push(x)
      );
    setUniPer(uniquePer && uniquePer);

    const tempTaxable = [];
    tempTaxable.push(
      ordersCart && ordersCart.reduce((a, b) => a + b.taxAbleAmount, 0)
    );

    var newTaxable = [];
    var newTaxable = tempTaxable.filter(function (elem, pos) {
      return tempTaxable.indexOf(elem) == pos;
    });
    setNewTaxable(newTaxable && newTaxable);

    const tempIgst = [];
    tempIgst.push(ordersCart && ordersCart.reduce((a, b) => a + b.taxIgst, 0));

    var newIgst = [];
    var newIgst = tempIgst.filter(function (elem, pos) {
      return tempIgst.indexOf(elem) == pos;
    });
    setNewIgst(newIgst && newIgst);

    const tempCgst = [];
    tempCgst.push(ordersCart && ordersCart.reduce((a, b) => a + b.taxCgst, 0));

    var newCgst = [];
    var newCgst = tempCgst.filter(function (elem, pos) {
      return tempCgst.indexOf(elem) == pos;
    });
    setNewCgst(newCgst && newCgst);

    const tempSgst = [];
    tempSgst.push(ordersCart && ordersCart.reduce((a, b) => a + b.taxSgst, 0));

    var newSgst = [];
    var newSgst = tempSgst.filter(function (elem, pos) {
      return tempSgst.indexOf(elem) == pos;
    });
    setNewSgst(newSgst && newSgst);

    const tempTotalTax = [];
    tempTotalTax.push(
      ordersCart && ordersCart.reduce((a, b) => a + b.taxTotal, 0)
    );

    var newTotalTax = [];
    var newTotalTax = tempTotalTax.filter(function (elem, pos) {
      return tempTotalTax.indexOf(elem) == pos;
    });
    setNewTotalTax(newTotalTax && newTotalTax);

    // const uniquePer = [];
    // tax &&
    //   tax.map((x) =>
    //     uniquePer.filter((a) => a.taxType == x.taxType).length > 0
    //       ? null
    //       : uniquePer.push(x)
    //   );
    // setUniPer(uniquePer && uniquePer);

    // const tempTaxable = [];
    // const tempIgst = [];
    // const tempCgst = [];
    // const tempSgst = [];
    // const tempTotalTax = [];
    // Promise.all(
    //   tax.map(async (id) => {
    //     const response = await fetch(
    //       localApi +
    //         `/taxSumary/list/byAdminIdAndTaxType/${
    //           userObj && userObj.admin.id
    //         }?taxType=${id.taxType}`
    //     );
    //     const data = await response.json();
    //     const d = data.filter((f) => taaxId.includes(f.taxId));
    //     // console.log(d);
    //     // console.log(data);
    //     tempTaxable.push(d && d.reduce((a, b) => a + b.taxableAmount, 0));
    //     tempIgst.push(d && d.reduce((a, b) => a + b.igst, 0));
    //     tempCgst.push(d && d.reduce((a, b) => a + b.cgst, 0));
    //     tempSgst.push(d && d.reduce((a, b) => a + b.sgst, 0));
    //     tempTotalTax.push(d && d.reduce((a, b) => a + b.totalTax, 0));
    //   })
    // ).then(() => {
    //   var newTaxable = [];
    //   var newIgst = [];
    //   var newCgst = [];
    //   var newSgst = [];
    //   var newTotalTax = [];

    //   // console.log(tempTaxable);

    //   var newTaxable = tempTaxable.filter(function (elem, pos) {
    //     return tempTaxable.indexOf(elem) == pos;
    //   });
    //   setNewTaxable(newTaxable && newTaxable);
    //   // console.log(newTaxable);

    //   var newIgst = tempIgst.filter(function (elem, pos) {
    //     return tempIgst.indexOf(elem) == pos;
    //   });
    //   setNewIgst(newIgst && newIgst);
    //   // console.log(newIgst);

    //   var newCgst = tempCgst.filter(function (elem, pos) {
    //     return tempCgst.indexOf(elem) == pos;
    //   });
    //   setNewCgst(newCgst && newCgst);
    //   // console.log(newCgst);

    //   var newSgst = tempSgst.filter(function (elem, pos) {
    //     return tempSgst.indexOf(elem) == pos;
    //   });
    //   setNewSgst(newSgst && newSgst);
    //   // console.log(newSgst);

    //   var newTotalTax = tempTotalTax.filter(function (elem, pos) {
    //     return tempTotalTax.indexOf(elem) == pos;
    //   });
    //   setNewTotalTax(newTotalTax && newTotalTax);
    //   // console.log(newTotalTax);
    // });
  }

  async function getProd() {
    const get = localApi + `/adminAddress/list/byAdminId/${userObj.admin.id}`;
    const results = await fetch(get, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const res = await results.json();
    setAdminAddress(
      res &&
      res.filter(
        (a) => a.addressType === "GSTIN ADDRESS" && a.active === true
      )
    );
    // console.log(res);
  }

  async function getTerms() {
    const get =
      localApi +
      `/termsAndCondition/byAdminAndClientId/${userObj.admin.id}/${client}`;
    const results = await fetch(get, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const res = await results.json();
    // console.log(res);
    setTermsClient(res && res);
  }

  useEffect(() => {
    getProd();
    getOrderList();
    getTerms();
  }, []);

  const handleRemove = (c) => {
    setSubmitteds(true);
    setCartId(c);
  };

  const handleConfirm = () => {
    // console.log(cartId.id);
    const cartMulti = viewCart.filter((i) => {
      return i.id !== cartId.id;
    });

    // console.log(cartMulti.length);

    const cartMid = cartMulti.map((i) => {
      return i.id;
    });

    const cartM = cartMulti.map((i) => {
      return {
        cartId: i.id,
        adminId: i.c_adminid,
        clientId: i.cId,
      };
    });

    // console.log(cartMid);

    const cartClientid = viewCart.map((i) => {
      // return i.clientId;
      return i.cId;
    });

    // console.log(viewCart);

    // console.log(cartClientid[0]);

    fetch(
      localApi +
      `/cart/update/byAdminAndCartIdAndFalse/${userObj.admin.id}/${cartId.id}`,
      {
        method: "PUT",
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        if (res.message === "Data Updated Successfully !!!") {
          alertTypeOps("success");
          alertMsgOps("Deleted Succesfully !!!");
          setSubmittedConfirm(true);

          const requestOptions = {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Basic ${credentials}`,
            },
            body: JSON.stringify({
              cartId: cartMid[0].toString(),
              clientId: cartClientid[0].toString(),
              multiCartId: cartM,
              amount: Math.round(
                cartMulti.reduce(
                  (a, b) => a + b.amount + b.cartPackCharge * b.cartQuantity,
                  0
                )
              ),
              advance: Math.round(
                cartMulti.reduce(
                  (a, b) =>
                    a +
                    b.metalGram *
                    b.setsPcs *
                    b.box *
                    b.sizeTotal *
                    (b.rawPrice + b.rawDifferent),
                  0
                )
              ),
              totalBox: cartMulti.reduce(
                (a, b) => a + b.sizeTotal,
                0
              ),
              totalCarton: cartMulti.reduce(
                (a, b) => parseInt(a) + parseInt(b.cartQuantity),
                0
              ),
            }),
          };

          // console.log(requestOptions);

          fetch(
            localApi +
            `/orders/update/byAdminAndOrdersId/${userObj.admin.id}/${order}`,
            requestOptions
          )
            .then((response) => response.json())
            .then((data) => {
              console.log(data);
            });
        }
      });
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  const hideAlerts = () => {
    setSubmitteds(false);
    setSubmittedConfirm(false);
    window.location.reload(false);
  };

  // var totalCal = 0;
  // var totalCalMix = 0;
  // var totalSum = 0;

  return (
    <div className={classTable.productPage}>
      <Card>
        <GridContainer justify="center" alignItems="center">
          {submitted ? (
            <SweetAlert
              type={alertType}
              style={{ display: "block", marginTop: "-100px" }}
              title={alertMsg}
              onConfirm={() => hideAlert()}
              confirmBtnCssClass={classSweet.button + " " + classSweet.success}
            />
          ) : null}
          {submitteds ? (
            <SweetAlert
              warning
              style={{ display: "block", marginTop: "100px" }}
              title="Are you sure?"
              onConfirm={handleConfirm}
              onCancel={() => hideAlerts()}
              confirmBtnCssClass={classSweet.button + " " + classSweet.success}
              cancelBtnCssClass={classSweet.button + " " + classSweet.danger}
              confirmBtnText="Yes, delete it!"
              cancelBtnText="Cancel"
              showCancel
            />
          ) : null}

          {submittedConfirm ? (
            <SweetAlert
              success
              style={{ display: "block", marginTop: "100px" }}
              title="Deleted!"
              onConfirm={() => hideAlerts()}
              onCancel={() => hideAlerts()}
              confirmBtnCssClass={classSweet.button + " " + classSweet.success}
            >
              Your item has been deleted.
            </SweetAlert>
          ) : null}
          <div className={classTable.container} style={{ border: "1px solid" }}>
            <GridContainer className={classTable.borderLine}>
              <GridItem md={2} sm={2} xs={2}>
                <img
                  src={userObj.admin.logo != null ? `https://inshabangles.com/imgstore/original_logo/${userObj.admin.logo}` : logo}
                  style={{ marginTop: "10px", height: "100px" }}
                />
              </GridItem>
              <GridItem
                md={8}
                sm={8}
                xs={8}
                className={
                  classTable.mlAuto +
                  " " +
                  classTable.mrAuto +
                  " " +
                  classTable.textCenter
                }
              >
                <span className={classTable.invoiceName}>
                  {ordList.gstCash === true
                    ? ordList.invoiceNumber === "0"
                      ? "SALES ORDER"
                      : "TAX INVOICE"
                    : ordList.invoiceNumber === "0"
                      ? "SALES ORDER"
                      : "CASH MEMO"}
                </span>
                <br />
                <span className={classTable.companyName}>
                  {adminAddress.map((a) => a.company)}
                </span>
                <br />
                <div className={classTable.space}>
                  <span className={classTable.address}>
                    {adminAddress.map((a) => a.plotHouseNo)}{" "}
                    {adminAddress.map((a) => a.areaColony)}{" "}
                    {adminAddress.map((a) => a.landMark)}{" "}
                    {adminAddress.map((a) => a.road)}
                    <br />
                    {adminAddress.map((a) => a.city)} {"-"}{" "}
                    {adminAddress.map((a) => a.zipCode)},{" "}
                    {adminAddress.map((a) => a.state)}, INDIA.
                    <br />{" "}
                    <strong className={classTable.gst}>
                      GSTIN : {adminAddress.map((a) => a.gstIn)}
                    </strong>{" "}
                  </span>
                  <br />
                  <span className={classTable.mobileTel}>
                    <ImMobile /> {adminAddress.map((a) => a.mobile)}{" "}
                    <IoLogoWhatsapp /> {adminAddress.map((a) => a.whatsApp)}{" "}
                    <MdEmail /> {userObj.email}
                    {/* {adminAddress.map((a) => a.email)} */}
                  </span>
                </div>
              </GridItem>
              <GridItem
                md={2}
                sm={2}
                xs={2}
                className={classTable.originalCopy}
              >
                <span>Original Copy</span>
              </GridItem>
            </GridContainer>
            <GridContainer className={classTable.borderLine}>
              <GridItem
                md={6}
                sm={6}
                xs={6}
                className={classTable.space + " " + classTable.verticalLine}
              >
                <GridContainer>
                  <GridItem xs={5} sm={5} md={5}>
                    <span className={classTable.invoicedetail}>Order No.</span>
                    <br />
                    <span className={classTable.invoicedetail}>
                      Invoice No.
                    </span>
                    <br />
                    <span className={classTable.invoicedetail}>
                      Date of Invoice
                    </span>
                    <br />
                    <span className={classTable.invoicedetail}>
                      Place of Supply
                    </span>
                    <br />
                    <span className={classTable.invoicedetail}>
                      Reverse Charge
                    </span>
                  </GridItem>
                  <GridItem xs={7} sm={7} md={7}>
                    <span
                      className={
                        classTable.invoicedetail + " " + classTable.gstIn
                      }
                    >
                      : {ordList.id}
                    </span>
                    <br />
                    <span
                      className={
                        classTable.invoicedetail + " " + classTable.gstIn
                      }
                    >
                      : {ordList.invoiceNumber}
                    </span>
                    <br />
                    <span
                      className={
                        classTable.invoicedetail + " " + classTable.gstIn
                      }
                    >
                      : {invoiceDate}
                    </span>
                    <br />
                    <span
                      className={
                        classTable.invoicedetail + " " + classTable.gstIn
                      }
                    >
                      : {regAddress1 && regAddress1.state}({tin ? tin : ""}
                      {/* {regAddress1 && regAddress1.tin} */}
                      )
                    </span>
                    <br />
                    <span
                      className={
                        classTable.invoicedetail + " " + classTable.gstIn
                      }
                    >
                      : {ordList.reverseCharge}
                    </span>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem md={6} sm={6} xs={6} className={classTable.space}>
                <GridContainer>
                  <GridItem xs={5} sm={5} md={5}>
                    <span className={classTable.invoicedetail}>Order Date</span>
                    <br />
                    <span className={classTable.invoicedetail}>GR/RR No.</span>
                    <br />
                    <span className={classTable.invoicedetail}>Transport</span>
                    <br />
                    <span className={classTable.invoicedetail}>Station</span>
                    <br />
                    <span className={classTable.invoicedetail}>
                      E-Way Bill No.
                    </span>
                  </GridItem>
                  <GridItem xs={7} sm={7} md={7}>
                    <span
                      className={
                        classTable.invoicedetail + " " + classTable.gstIn
                      }
                    >
                      : {ordersDate}
                    </span>
                    <br />
                    <span
                      className={
                        classTable.invoicedetail + " " + classTable.gstIn
                      }
                    >
                      : {ordList.grRrNumber}
                    </span>
                    <br />
                    <span
                      className={
                        classTable.invoicedetail + " " + classTable.gstIn
                      }
                    >
                      : {branch.branchName}
                    </span>
                    <br />
                    <span
                      className={
                        classTable.invoicedetail + " " + classTable.gstIn
                      }
                    >
                      : {ordList.stationName}
                    </span>
                    <br />
                    <span
                      className={
                        classTable.invoicedetail + " " + classTable.gstIn
                      }
                    >
                      : {ordList.eWayBillNumber}
                    </span>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
            <GridContainer className={classTable.borderLine}>
              <GridItem
                md={6}
                sm={6}
                xs={6}
                className={classTable.space + " " + classTable.verticalLine}
              >
                <span
                  className={classTable.invoiceDetailsHead}
                  style={{ textDecorationLine: "underline" }}
                >
                  Billed to :-
                </span>
                <br />
                <span className={classTable.invoicedetailCom}>
                  {regAddress1 && regAddress1.company
                    ? regAddress1.company
                    : regAddress1 &&
                    regAddress1.firstName + " " + regAddress1 &&
                    regAddress1.lastName}
                </span>
                <br />
                <span className={classTable.invoicedetail}>
                  {regAddress1 && regAddress1.plotHouseNo}{" "}
                  {regAddress1 && regAddress1.areaColony}
                </span>
                <br />
                <span className={classTable.invoicedetail}>
                  {regAddress1 && regAddress1.landMark}{" "}
                  {regAddress1 && regAddress1.road}{" "}
                  {regAddress1 && regAddress1.zipCode}
                </span>{" "}
                <br />
                <span className={classTable.invoicedetail}>
                  {regAddress1 && regAddress1.city}{" "}
                  {regAddress1 && regAddress1.state}
                </span>
                <br />
                <span className={classTable.invoicedetail}>
                  {regAddress1 && regAddress1.mobile ? <ImMobile /> : ""}{" "}
                  {regAddress1 && regAddress1.mobile ? regAddress1.mobile : ""}{" "}
                  {regAddress1 && regAddress1.whatsApp ? (
                    <IoLogoWhatsapp />
                  ) : (
                    ""
                  )}{" "}
                  {regAddress1 && regAddress1.whatsApp
                    ? regAddress1.whatsApp
                    : ""}
                </span>
                <br />
                <span
                  className={classTable.invoicedetail + " " + classTable.gstIn}
                >
                  {regAddress1 && regAddress1.gstIn
                    ? "GSTIN / UIN"
                    : "Aadhar No."}{" "}
                  &nbsp;&nbsp;:{" "}
                  {regAddress1 && regAddress1.gstIn
                    ? regAddress1.gstIn
                    : regAddress1 && regAddress1.aadharNumber}
                </span>
              </GridItem>
              <GridItem md={6} sm={6} xs={6} className={classTable.space}>
                <span
                  className={classTable.invoiceDetailsHead}
                  style={{ textDecorationLine: "underline" }}
                >
                  Shipped to :-
                </span>
                <br />
                <span className={classTable.invoicedetailCom}>
                  {regAddress2 && regAddress2.company
                    ? regAddress2.company
                      ? regAddress2.company
                      : regAddress2 &&
                      regAddress2.firstName + " " + regAddress2 &&
                      regAddress2.lastName
                    : regAddress1.company
                      ? regAddress1.company
                      : regAddress1 &&
                      regAddress1.firstName + " " + regAddress1 &&
                      regAddress1.lastName}
                </span>
                <br />
                <span className={classTable.invoicedetail}>
                  {regAddress2 && regAddress2.plotHouseNo
                    ? regAddress2.plotHouseNo
                    : regAddress1 && regAddress1.plotHouseNo}{" "}
                  {regAddress2 && regAddress2.areaColony
                    ? regAddress2.areaColony
                    : regAddress1 && regAddress1.areaColony}
                </span>{" "}
                <br />
                <span className={classTable.invoicedetail}>
                  {regAddress2 && regAddress2.landMark
                    ? regAddress2.landMark
                    : regAddress1 && regAddress1.landMark}{" "}
                  {regAddress2 && regAddress2.road
                    ? regAddress2.road
                    : regAddress1 && regAddress1.road}{" "}
                  {regAddress2 && regAddress2.zipCode
                    ? regAddress2.zipCode
                    : regAddress1 && regAddress1.zipCode}{" "}
                </span>{" "}
                <br />
                <span className={classTable.invoicedetail}>
                  {regAddress2 && regAddress2.city
                    ? regAddress2.city
                    : regAddress1 && regAddress1.city}{" "}
                  {regAddress2 && regAddress2.state
                    ? regAddress2.state
                    : regAddress1 && regAddress1.state}
                </span>
                <br />
                <span className={classTable.invoicedetail}>
                  {regAddress2 && regAddress2.mobile ? (
                    <ImMobile />
                  ) : regAddress1 && regAddress1.mobile ? (
                    <ImMobile />
                  ) : (
                    ""
                  )}{" "}
                  {regAddress2 && regAddress2.mobile
                    ? regAddress2.mobile
                    : regAddress1 && regAddress1.mobile
                      ? regAddress1.mobile
                      : ""}{" "}
                  {regAddress2 && regAddress2.whatsApp ? (
                    <TiPhoneOutline />
                  ) : regAddress1 && regAddress1.whatsApp ? (
                    <TiPhoneOutline />
                  ) : (
                    ""
                  )}{" "}
                  {regAddress2 && regAddress2.whatsApp
                    ? regAddress2.whatsApp
                    : regAddress1 && regAddress1.whatsApp
                      ? regAddress1.whatsApp
                      : ""}
                </span>
                <br />
                <span
                  className={classTable.invoicedetail + " " + classTable.gstIn}
                >
                  {regAddress2 && regAddress2.gstIn
                    ? regAddress2.gstIn
                      ? "GSTIN / UIN :"
                      : "Aadhar No. :"
                    : regAddress1.gstIn
                      ? "GSTIN / UIN :"
                      : "Aadhar No. :"}
                  {regAddress2 && regAddress2.gstIn
                    ? regAddress2.gstIn
                      ? regAddress2.gstIn
                      : regAddress2 && regAddress2.aadharNumber
                    : regAddress1.gstIn
                      ? regAddress1.gstIn
                      : regAddress1 && regAddress1.aadharNumber}
                </span>
              </GridItem>
            </GridContainer>
            <Table
              tableHead={[
                <span className={classTable.tableHead} key="sr">
                  Sr. No.
                </span>,
                <span className={classTable.tableHead} key="des">
                  Description of Goods
                </span>,
                <span
                  className={classTable.tableHead + " " + classTable.headRight}
                  key="hsn"
                >
                  HSN/SAC Code
                </span>,
                <span
                  className={classTable.tableHead + " " + classTable.headRight}
                  key="ctn"
                >
                  Carton
                </span>,
                <span
                  className={classTable.tableHead + " " + classTable.headRight}
                  key="qty"
                >
                  Quantity
                </span>,
                <span
                  className={classTable.tableHead + " " + classTable.headRight}
                  key="pri"
                >
                  Price(₹)
                </span>,
                <span
                  className={classTable.tableHead + " " + classTable.headRight}
                  key="amt"
                >
                  Amount(₹)
                </span>,
                "",
              ]}
              tableData={[
                ...viewCart.map((c, index) => [
                  <span className={classTable.listNumber} key={c.id}>
                    {index + 1}
                  </span>,
                  <span key={c.id}>
                    <span>
                      <span className={classTable.descParagragh}>
                        IMMITATION BANGLES{" "}
                      </span>
                      <span>{"(Product No. " + c.pId + ")"}</span>
                    </span>
                    <GridContainer className={classTable.sizePadding}>
                      {c.size1x8 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.size1x8 == null
                            ? ""
                            : c.size1x8 == 0
                              ? ""
                              : "1x8"}
                          <br />
                          {c.size1x8 == null
                            ? ""
                            : c.size1x8 == 0
                              ? ""
                              : c.size1x8}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.size1x10 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.size1x10 == null
                            ? ""
                            : c.size1x10 == 0
                              ? ""
                              : "1x10"}
                          <br />
                          {c.size1x10 == null
                            ? ""
                            : c.size1x10 == 0
                              ? ""
                              : c.size1x10}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.size1x12 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.size1x12 == null
                            ? ""
                            : c.size1x12 == 0
                              ? ""
                              : "1x12"}
                          <br />
                          {c.size1x12 == null
                            ? ""
                            : c.size1x12 == 0
                              ? ""
                              : c.size1x12}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.size1x14 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.size1x14 == null
                            ? ""
                            : c.size1x14 == 0
                              ? ""
                              : "1x14"}
                          <br />
                          {c.size1x14 == null
                            ? ""
                            : c.size1x14 == 0
                              ? ""
                              : c.size1x14}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.size2x0 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.size2x0 == null
                            ? ""
                            : c.size2x0 == 0
                              ? ""
                              : "2x0"}
                          <br />
                          {c.size2x0 == null
                            ? ""
                            : c.size2x0 == 0
                              ? ""
                              : c.size2x0}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.size2x2 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.size2x2 == null
                            ? ""
                            : c.size2x2 == 0
                              ? ""
                              : "2x2"}
                          <br />
                          {c.size2x2 == null
                            ? ""
                            : c.size2x2 == 0
                              ? ""
                              : c.size2x2}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.size2x4 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.size2x4 == null
                            ? ""
                            : c.size2x4 == 0
                              ? ""
                              : "2x4"}
                          <br />
                          {c.size2x4 == null
                            ? ""
                            : c.size2x4 == 0
                              ? ""
                              : c.size2x4}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.size2x6 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.size2x6 == null
                            ? ""
                            : c.size2x6 == 0
                              ? ""
                              : "2x6"}
                          <br />
                          {c.size2x6 == null
                            ? ""
                            : c.size2x6 == 0
                              ? ""
                              : c.size2x6}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.size2x8 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.size2x8 == null
                            ? ""
                            : c.size2x8 == 0
                              ? ""
                              : "2x8"}
                          <br />
                          {c.size2x8 == null
                            ? ""
                            : c.size2x8 == 0
                              ? ""
                              : c.size2x8}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.size2x10 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.size2x10 == null
                            ? ""
                            : c.size2x10 == 0
                              ? ""
                              : "2x10"}
                          <br />
                          {c.size2x10 == null
                            ? ""
                            : c.size2x10 == 0
                              ? ""
                              : c.size2x10}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.size2x12 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.size2x12 == null
                            ? ""
                            : c.size2x12 == 0
                              ? ""
                              : "2x12"}
                          <br />
                          {c.size2x12 == null
                            ? ""
                            : c.size2x12 == 0
                              ? ""
                              : c.size2x12}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.size2x14 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.size2x14 == null
                            ? ""
                            : c.size2x14 == 0
                              ? ""
                              : "2x14"}
                          <br />
                          {c.size2x14 == null
                            ? ""
                            : c.size2x14 == 0
                              ? ""
                              : c.size2x14}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.size3x0 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.size3x0 == null
                            ? ""
                            : c.size3x0 == 0
                              ? ""
                              : "3x0"}
                          <br />
                          {c.size3x0 == null
                            ? ""
                            : c.size3x0 == 0
                              ? ""
                              : c.size3x0}
                        </span>
                      ) : (
                        ""
                      )}
                    </GridContainer>
                    <GridContainer className={classTable.sizePadding}>
                      {c.size1x9 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.size1x9 == null
                            ? ""
                            : c.size1x9 == 0
                              ? ""
                              : "1x9"}
                          <br />
                          {c.size1x9 == null
                            ? ""
                            : c.size1x9 == 0
                              ? ""
                              : c.size1x9}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.size1x11 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.size1x11 == null
                            ? ""
                            : c.size1x11 == 0
                              ? ""
                              : "1x11"}
                          <br />
                          {c.size1x11 == null
                            ? ""
                            : c.size1x11 == 0
                              ? ""
                              : c.size1x11}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.size1x13 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.size1x13 == null
                            ? ""
                            : c.size1x13 == 0
                              ? ""
                              : "1x13"}
                          <br />
                          {c.size1x13 == null
                            ? ""
                            : c.size1x13 == 0
                              ? ""
                              : c.size1x13}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.size1x15 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.size1x15 == null
                            ? ""
                            : c.size1x15 == 0
                              ? ""
                              : "1x15"}
                          <br />
                          {c.size1x15 == null
                            ? ""
                            : c.size1x15 == 0
                              ? ""
                              : c.size1x15}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.size2x1 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.size2x1 == null
                            ? ""
                            : c.size2x1 == 0
                              ? ""
                              : "2x1"}
                          <br />
                          {c.size2x1 == null
                            ? ""
                            : c.size2x1 == 0
                              ? ""
                              : c.size2x1}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.size2x3 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.size2x3 == null
                            ? ""
                            : c.size2x3 == 0
                              ? ""
                              : "2x3"}
                          <br />
                          {c.size2x3 == null
                            ? ""
                            : c.size2x3 == 0
                              ? ""
                              : c.size2x3}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.size2x5 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.size2x5 == null
                            ? ""
                            : c.size2x5 == 0
                              ? ""
                              : "2x5"}
                          <br />
                          {c.size2x5 == null
                            ? ""
                            : c.size2x5 == 0
                              ? ""
                              : c.size2x5}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.size2x7 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.size2x7 == null
                            ? ""
                            : c.size2x7 == 0
                              ? ""
                              : "2x7"}
                          <br />
                          {c.size2x7 == null
                            ? ""
                            : c.size2x7 == 0
                              ? ""
                              : c.size2x7}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.size2x9 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.size2x9 == null
                            ? ""
                            : c.size2x9 == 0
                              ? ""
                              : "2x9"}
                          <br />
                          {c.size2x9 == null
                            ? ""
                            : c.size2x9 == 0
                              ? ""
                              : c.size2x9}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.size2x11 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.size2x11 == null
                            ? ""
                            : c.size2x11 == 0
                              ? ""
                              : "2x11"}
                          <br />
                          {c.size2x11 == null
                            ? ""
                            : c.size2x11 == 0
                              ? ""
                              : c.size2x11}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.size2x13 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.size2x13 == null
                            ? ""
                            : c.size2x13 == 0
                              ? ""
                              : "2x13"}
                          <br />
                          {c.size2x13 == null
                            ? ""
                            : c.size2x13 == 0
                              ? ""
                              : c.size2x13}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.size2x15 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.size2x15 == null
                            ? ""
                            : c.size2x15 == 0
                              ? ""
                              : "2x15"}
                          <br />
                          {c.size2x15 == null
                            ? ""
                            : c.size2x15 == 0
                              ? ""
                              : c.size2x15}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.size3x1 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.size3x1 == null
                            ? ""
                            : c.size3x1 == 0
                              ? ""
                              : "3x1"}
                          <br />
                          {c.size3x1 == null
                            ? ""
                            : c.size3x1 == 0
                              ? ""
                              : c.size3x1}
                        </span>
                      ) : (
                        ""
                      )}
                    </GridContainer>
                    {/* <span className={classTable.listDescription}>
                      {c && c.name ? c.name + ", " : ""}{" "}
                      {c && c.mycomment ? c.mycomment : ""}
                    </span> */}
                  </span>,
                  <span
                    className={
                      classTable.headRight + " " + classTable.listNumber
                    }
                    key={c.id}
                  >
                    {c.hsnSacCode}
                  </span>,
                  <span
                    className={
                      classTable.headRight + " " + classTable.listNumber
                    }
                    key={c.id}
                  >
                    {c.cartQuantity}
                  </span>,
                  <span
                    className={
                      classTable.headRight + " " + classTable.listNumber
                    }
                    key={c.id}
                  >
                    {c.sizeTotal}
                  </span>,
                  <span
                    className={
                      classTable.headRight + " " + classTable.listNumber
                    }
                    key={c.id}
                  >
                    {c.gstCash === true
                      ? c.taxInclude.toFixed(2)
                      : c.cashPrice.toFixed(2)}
                  </span>,
                  <span
                    className={
                      classTable.headRight + " " + classTable.listNumber
                    }
                    key={c.id}
                  >
                    {Math.round(c.amount)}
                  </span>,
                  cartLength != 1 ? (
                    <Tooltip
                      id="close1"
                      title="Cart Remove"
                      placement="left"
                      classes={{ tooltip: classTable.tooltip }}
                      key={c.id}
                    >
                      <Button
                        link
                        className={classTable.actionButton}
                        onClick={() => handleRemove(c)}
                      >
                        <Close style={{ color: "red" }} />
                      </Button>
                    </Tooltip>
                  ) : (
                    ""
                  ),
                ]),
                [
                  "",
                  <span className={classTable.listNumber} key={1}>
                    Carton Charges
                    <br />
                    <small className={classTable.invoicedetail}>
                      Grand Total
                    </small>
                  </span>,
                  "",
                  <span key={3}>
                    <span
                      className={
                        classTable.headRight + " " + classTable.listNumberA
                      }
                    >
                      Carton-
                      {viewCart.reduce((a, b) => a + b.cartQuantity, 0)}
                    </span>
                    <br />
                  </span>,
                  <span key={3}>
                    <br />
                    <span
                      className={
                        classTable.headRight + " " + classTable.listNumberA
                      }
                    >
                      Unit-
                      {viewCart &&
                        viewCart.reduce(
                          (a, b) => a + b.sizeTotal,
                          0
                        )}
                    </span>
                  </span>,
                  "",
                  <span key={4}>
                    <span
                      className={
                        classTable.headRight + " " + classTable.listNumber
                      }
                    >
                      ₹{" "}
                      {viewCart.reduce(
                        (a, b) => a + b.cartPackCharge * b.cartQuantity,
                        0
                      ) + "/-"}
                    </span>
                    <br />
                    <span
                      className={
                        classTable.headRight + " " + classTable.listNumberA
                      }
                    >
                      ₹{" "}
                      {Math.round(
                        viewCart.reduce(
                          (a, b) =>
                            a + b.amount + b.cartPackCharge * b.cartQuantity,
                          0
                        )
                      ) + "/-"}
                    </span>
                  </span>,
                  "",
                ],
              ]}
            />
            {/* <hr style={{ borderBottom: "1px solid #999" }} /> */}
            <GridContainer className={classTable.sizePadding}>
              <span>
                <small
                  className={classTable.taxDesc}
                  style={{ textDecorationLine: "underline" }}
                >
                  Tax Rate
                </small>
                <br />
                {uniPer.map((t) => (
                  <small className={classTable.invoicedetail} key={t.taxId}>
                    {t.taxType + "%"}
                    <br />
                  </small>
                ))}
              </span>
              <span style={{ marginLeft: "15px", marginRight: "15px" }}>
                <small
                  className={classTable.taxDesc}
                  style={{ textDecorationLine: "underline" }}
                >
                  Taxable Ami
                </small>
                <br />
                {newTaxable.map((n) => (
                  <small className={classTable.invoicedetail} key={n}>
                    {n.toFixed(3)}
                    <br />
                  </small>
                ))}
              </span>
              <span style={{ marginRight: "15px" }}>
                {ordList.saleType == 0 ? (
                  <span>
                    <small
                      className={classTable.taxDesc}
                      style={{ textDecorationLine: "underline" }}
                    >
                      CGST Amt.
                    </small>
                  </span>
                ) : (
                  <small
                    className={classTable.taxDesc}
                    style={{ textDecorationLine: "underline" }}
                  >
                    IGST Amt.
                  </small>
                )}
                <br />
                {ordList.saleType == 0 ? (
                  <span>
                    <small className={classTable.invoicedetail}>
                      {newCgst.map((n) => (
                        <small className={classTable.invoicedetail} key={n}>
                          {n.toFixed(3)}
                          <br />
                        </small>
                      ))}
                    </small>
                  </span>
                ) : (
                  <small className={classTable.invoicedetail}>
                    {newIgst.map((n) => (
                      <small className={classTable.invoicedetail} key={n}>
                        {n.toFixed(3)}
                        <br />
                      </small>
                    ))}
                  </small>
                )}
              </span>
              <span style={{ marginRight: "15px" }}>
                {ordList.saleType == 0 ? (
                  <span>
                    <small
                      className={classTable.taxDesc}
                      style={{
                        textDecorationLine: "underline",
                      }}
                    >
                      SGST Amt.
                    </small>{" "}
                  </span>
                ) : (
                  ""
                )}
                <br />
                {ordList.saleType == 0 ? (
                  <span>
                    <small className={classTable.invoicedetail}>
                      {newSgst.map((n) => (
                        <small className={classTable.invoicedetail} key={n}>
                          {n.toFixed(3)}
                          <br />
                        </small>
                      ))}
                    </small>{" "}
                  </span>
                ) : (
                  ""
                )}
              </span>
              <span>
                <small
                  className={classTable.taxDesc}
                  style={{ textDecorationLine: "underline" }}
                >
                  Total Tax
                </small>
                <br />
                {newTotalTax.map((n) => (
                  <small className={classTable.invoicedetail} key={n}>
                    {n.toFixed(3)}
                    <br />
                  </small>
                ))}
              </span>
            </GridContainer>
            <GridContainer
              // style={{ marginTop: "2px" }}
              className={classTable.borderLine}
            >
              <GridItem md={12} xs={12} sm={12}>
                <span
                  className={classTable.invoicedetail + " " + classTable.gstIn}
                >
                  Amount Chargeable (in words) INR :- {finalAmt} Only
                </span>
              </GridItem>
            </GridContainer>
            <GridContainer
              className={classTable.borderLine}
            // style={{ marginTop: "2px" }}
            >
              <GridItem md={12} xs={12} sm={12}>
                <span
                  className={classTable.invoicedetail + " " + classTable.gstIn}
                >
                  Company's Bank Details:- State Bank of India, INSHA BANGLES,
                  A/c No.: 33975373956, SBIN0017293, SHIFT/BIC Code: SBININBB905
                </span>
                <br />
                <span className={classTable.invoicedetailTermAddress}>
                  Address:- SHOP NO 11, BHOOMI CLASSIC CHS LTD, New Link Rd,
                  Malad West, Mumbai, Maharashtra 400064,
                </span>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem
                md={8}
                sm={8}
                xs={8}
                className={classTable.space}
                style={{
                  borderRight: "1px solid black",
                }}
              >
                <span
                  className={classTable.taxDesc}
                  style={{
                    textDecorationLine: "underline",
                  }}
                >
                  Terms & Conditions
                </span>
                <br />
                <span className={classTable.taxDesc}>E & O.C</span>
                <ol className={classTable.invoicedetailTerm}>
                  {termsClient.length != 0
                    ? termsClient.map((tc) => (
                      <li key={tc.id}>{tc.discription}</li>
                    ))
                    : termsDefault.map((t) => (
                      <li key={t.id}>{t.discription}</li>
                    ))}
                </ol>
                {/* <br /> */}
                <span
                  className={classTable.invoicedetail + " " + classTable.gstIn}
                  style={{
                    float: "right",
                  }}
                >
                  Receiver's Signature:
                  <br />
                </span>
              </GridItem>
              <GridItem md={4} sm={4} xs={4}>
                <br />
                <div
                // style={{
                //   backgroundImage: `url(${logo})`,
                //   backgroundRepeat: "no-repeat",
                //   backgroundPosition: "center",
                //   backgroundSize: "fixed",
                // }}
                >
                  <span
                    className={
                      classTable.invoicedetailTerm + " " + classTable.gstIn
                    }
                  >
                    <small style={{ marginLeft: "30%" }}>
                      for INSHA BANGLES
                    </small>
                    <br />
                    <img
                      src={userObj.admin.signature != null ? `https://inshabangles.com/imgstore/original_logo/${userObj.admin.signature}` : ""}
                      style={{ marginLeft: "40%" }} />
                    <br />
                    <small style={{ marginLeft: "30%" }}>
                      Authorised Signatory
                    </small>
                  </span>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </GridContainer>
        <div className={classTable.brand} style={{ marginLeft: "100px" }}>
          <Button
            className={classTable.print}
            color="rose"
            round
            onClick={() => {
              window.print();
              window.location.href = "/admin/myOrder";
            }}
          >
            Print
          </Button>
        </div>
      </Card>
    </div>
  );
}
