import React, { useEffect } from "react";
import axios from "axios";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
// core components
import LinkIcon from "@material-ui/icons/Link";
import { IoLogoWhatsapp } from "react-icons/io";
import { ImMobile } from "react-icons/im";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { localApi, credentials } from "utils/imgApi";
import Slide from "@material-ui/core/Slide";
import Select from "@material-ui/core/Select";
import Selects from "react-select";

import stylesPrint from "assets/jss/material-dashboard-pro-react/views/crmUserListPrintStyle";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import styleDailog from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import styCheckbox from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import style from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

import SweetAlert from "react-bootstrap-sweetalert";
import styleSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);
const useStyleDailog = makeStyles(styleDailog);
const useStylePrint = makeStyles(stylesPrint);
const useSty = makeStyles(styCheckbox);
const useStyle = makeStyles(style);
const useStyl = makeStyles(styleSweet);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function ContactListTransfer() {
  const [dataOrders, setDataOrder] = React.useState([]);
  const [makingCheckboxMaking, setMakingCheckboxMaking] = React.useState("");
  const [checkBoxMaking, setCheckBoxmaking] = React.useState([]);
  const [makChecked, setMakChecked] = React.useState("");
  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [items, setItems] = React.useState([]);
  const [crmObj, setCrmObj] = React.useState("");

  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);

  const classes = useStyles();
  const classCheck = useSty();
  const classTable = useStylePrint();
  const classeDailog = useStyleDailog();
  const history = useHistory();
  const classe = useStyle();
  const classSweet = useStyl();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const handleCheckBoxMaking = (event, or) => {
    // setCheckBoxmaking(e.target.checked);
    // const { name, checked } = event.target;

    setCheckBoxmaking((prev) => {
      if (prev.includes(or)) {
        return prev.filter((x) => x !== or);
      } else {
        return [...prev, or];
      }
    });

    setMakChecked((prev) => {
      if (prev.includes(or?.id)) {
        return prev.filter((x) => x !== or?.id);
      } else {
        return [...prev, or?.id];
      }
    });
  };

  async function getSets() {
    const api = localApi + `/contact/list/byCrmReferenceIdAndActiveFalse/${userObj.id}`;
    // const api =
    //   localApi +
    //   `/contact/list/byCrmReferenceAndAdminId/${userObj.id}/${userObj.admin.id}`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await result.json();
    // console.log(getResult);
    setDataOrder(
      getResult.map((or, key) => {
        return {
          sino: key + 1,
          firstName: or && or.company ? or.company : or.firstname + "" + or.lastname,
          mobile: (
            <div>
              <a href={`tel: ${or.mobile}`}>
                <ImMobile color="black" /> {or.mobile}
              </a>
            </div>
          ),
          whatsapp: (
            <div>
              {or.whatsapp != null ? 
              <a
                href={`https://api.whatsapp.com/send/?phone=${or.whatsapp}&text=Hello&app_absent=0`}
                target={"_blank"}
                rel="noreferrer"
              >
                <IoLogoWhatsapp color="green" /> {or.whatsapp}
              </a> : "" }
            </div>
          ),
          email: or.email,
          type: or.type,
          city: or.city,
          actions: (
            <div className="actions-right">
              {or.url != null ? (
                <Button
                  justIcon
                  round
                  simple
                  // onClick={() => {}}
                  target={"_blank"}
                  rel="noreferrer"
                  href={or.url}
                  color="info"
                  className="like"
                >
                  <LinkIcon />
                </Button>
              ) : (
                ""
              )}{" "}
              <FormControlLabel
                key={or.id}
                control={
                  <Checkbox
                    tabIndex={-1}
                    // checked={checkBoxMaking.includes(or)}
                    name={or}
                    onChange={(e) => {
                      handleCheckBoxMaking(e, or);
                    }}
                    checkedIcon={<Check className={classCheck.checkedIcon} />}
                    icon={<Check className={classCheck.uncheckedIcon} />}
                    classes={{
                      checked: classCheck.checkedRose,
                      root: classCheck.checkRoot,
                    }}
                  />
                }
                classes={{
                  label:
                    classCheck.label +
                    (makingCheckboxMaking === "error"
                      ? " " + classCheck.labelError
                      : ""),
                }}
                // label={making.description + "> " + making.price}
              />
              {/* <Button justIcon round simple color="danger" className="remove">
                <VisibilityIcon />
              </Button>{" "} */}
            </div>
          ),
        };
      })
    );
  }

  useEffect(() => {
    getSets();
  }, []);

  async function getUsers() {
    const api = localApi + `/register/list/byAdminId/${userObj.admin.id}`;
    const results = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await results.json();
    setItems(getResult.filter((r) => r.type === "Crm"));
    // console.log(getResult);
  }

  useEffect(() => {
    getUsers();
  }, []);

  const handleBoots = (simpleSelect) => {
    setSimpleSelect(simpleSelect);

    if (simpleSelect.value) {
      let newSpli = simpleSelect.value.split("-");
      const ids = items.find((f) => f.company === newSpli[0]);
      // console.log(ids);
      setCrmObj(ids);
    }
  };

  const SubmitTransfer = () => {
    // console.log(crmObj);

    const contactMid = checkBoxMaking.map((i) => {
      return i.id;
    });

    // console.log(contactMid);

    const jsonData = {
      crmReferenceId: crmObj.id,
    };

    contactMid.forEach((x) =>
      axios
        .put(
          localApi + `/contact/updateCrmReferenceId/byId/${x}`,
          jsonData, // Here you send the JSON body
          {
            headers: {
              Authorization: `Basic ${credentials}`,
              "Content-Type": "application/json", // Ensure the content type is set to JSON
            },
          }
        )
        .then((res) => {
          // console.log(res.data);
          if (res.data.message === "Data Updated Successfully !!!") {
            alertTypeOps("success");
            alertMsgOps("Successfully !!!");
            setSubmitted(true);
          }
        })
        .catch((err) => {
          // Handle error
          console.error(err);
        })
    );
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  const mystyle = {
    paddingLeft: 12,
    flex: 1,
  };

  const select = {
    width: "100%",
    maxWidth: 600,
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
    }),
    menu: (state) => ({
      color: "#d81b60",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      return { ...provided, opacity, transition };
    },
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Contact Pending List & Transfer
            </h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              columns={[
                {
                  Header: "SI.No.",
                  accessor: "sino",
                },
                {
                  Header: "Co. & Full Name",
                  accessor: "firstName",
                },
                // {
                //   Header: "Date",
                //   accessor: "date",
                // },
                {
                  Header: "Mobile",
                  accessor: "mobile",
                },
                {
                  Header: "Whatsapp",
                  accessor: "whatsapp",
                },
                {
                  Header: "Email",
                  accessor: "email",
                },
                {
                  Header: "Type",
                  accessor: "type",
                },
                {
                  Header: "City",
                  accessor: "city",
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                },
              ]}
              data={dataOrders}
            />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>CRM List</h4>
          </CardHeader>
          <CardBody>
            <form>
              {submitted ? (
                <SweetAlert
                  type={alertType}
                  style={{ display: "block", marginTop: "-100px" }}
                  title={alertMsg}
                  onConfirm={() => hideAlert()}
                  confirmBtnCssClass={
                    classSweet.button + " " + classSweet.success
                  }
                >
                  Transfer Contact
                </SweetAlert>
              ) : null}
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <label className={classe.selectLabel}>Select Client</label>
                  <Selects
                    styles={customStyles}
                    style={select}
                    required={true}
                    placeholder="Select Client..."
                    value={simpleSelect}
                    name="simpleSelect"
                    onChange={handleBoots}
                    options={
                      items &&
                      items.map((c) => {
                        return {
                          label:
                            c && c.company
                              ? c.company + "-" + c.mobile
                              : c.firstname + " " + c.lastname + "-" + c.mobile,
                          value:
                            c && c.company
                              ? c.company + "-" + c.mobile
                              : c.firstname + " " + c.lastname + "-" + c.mobile,
                        };
                      })
                    }
                  />
                </GridItem>
                {makChecked.length != 0 ? (
                  <div
                    style={{
                      float: "right",
                      marginTop: "15px",
                      marginBottom: "15px",
                    }}
                  >
                    <Button color="rose" round onClick={SubmitTransfer}>
                      Transfer
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </GridContainer>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
