import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Datetime from "react-datetime";
import { localApi, credentials } from "utils/imgApi";
import axios from "axios";
import Selects from "react-select";
import PersonPinCircleIcon from "@material-ui/icons/PersonPinCircle";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import SweetAlert from "react-bootstrap-sweetalert";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import style from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styleCheck from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import styDrop from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(style);
const useStyleCheck = makeStyles(styleCheck);
const useStyl = makeStyles(styDrop);

export default function CallReceipt() {
  const [itemClient, setItemClient] = React.useState([]);
  const [typeItem, setTypeItem] = React.useState([]);
  const [itemProd, setItemProd] = React.useState([]);
  const [checkedA, setCheckedA] = React.useState(true);
  const [checkedProd, setCheckedProd] = React.useState(false);
  const [simpleSelectContact, setSimpleSelectContact] = React.useState("");
  const [simpleSelectContactErr, setSimpleSelectContactErr] = React.useState(
    ""
  );
  const [contactDetails, setContactDetails] = React.useState("");
  const [simpleSelectCategory, setSimpleSelectCategory] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [simpleSelectSource, setSimpleSelectSource] = React.useState("");
  const [simpleSelectSourceErr, setSimpleSelectSourceErr] = React.useState("");
  const [simpleSelectProductItem, setSimpleSelectProductItem] = React.useState(
    ""
  );
  const [
    simpleSelectProductItemErr,
    setSimpleSelectProductItemErr,
  ] = React.useState("");
  const [productDetails, setProductDetails] = React.useState("");
  const [image, setImage] = React.useState("");
  const [prodCategory, setProdCategory] = React.useState("");
  const [prodSize, setProdSize] = React.useState("");
  const [prodMetalGram, setProdMetalGram] = React.useState("");
  const [prodReadyGram, setProdReadyGram] = React.useState("");
  const [prodCash, setProdCash] = React.useState("");
  const [prodTax, setProdTax] = React.useState("");
  const [prodTaxCash, setProdTaxCash] = React.useState("");
  const [callReceiptRemarks, setCallReceiptRemarks] = React.useState("");
  const [suggestedActionRemaks, setSuggestedActionRemarks] = React.useState("");
  const [simpleSelectCallStatus, setSimpleSelectCallStatus] = React.useState(
    ""
  );
  const [
    simpleSelectCallStatusErr,
    setSimpleSelectCallStatusErr,
  ] = React.useState("");
  const [
    simpleSelectCallSubStatus,
    setSimpleSelectCallSubStatus,
  ] = React.useState("");
  const [
    simpleSelectCallSubStatusErr,
    setSimpleSelectCallSubStatusErr,
  ] = React.useState("");
  const [simpleSelectNextAction, setSimpleSelectNextAction] = React.useState(
    ""
  );
  const [
    simpleSelectNextActionErr,
    setSimpleSelectNextActionErr,
  ] = React.useState("");
  const [AfterDate, setAfterDate] = React.useState("");
  const [AfterDateErr, setAfterDateErr] = React.useState("");
  const [AfterTime, setAfterTime] = React.useState("");
  const [AfterTimeErr, setAfterTimeErr] = React.useState("");
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [btn, setBtn] = React.useState(false);

  const classes = useStyles();
  const classSweet = useStyle();
  const classCheck = useStyleCheck();
  const classe = useStyl();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  async function getCrmData() {
    const api =
      localApi +
      `/register/list/byAdminAndTypeAndCrmReferenceIdAndOpenCloseCrm/${userObj.admin.id}/${userObj.id}/false`;
    const results = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await results.json();
    // setItemClient(getResult.filter((r) => r.type !== "Crm" && r.crmReferenceId === userObj.id && r.openclosecrm === "false"));
    // console.log(getResult);
    setItemClient(getResult && getResult);
  }

  useEffect(() => {
    getCrmData();
  }, []);

  async function getType() {
    const api = localApi + `/type/list`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await result.json();
    setTypeItem(
      getResult.TypeList.filter(
        (item) =>
          item.title !== "Control" &&
          item.title !== "Admin" &&
          item.title !== "Crm"
      )
    );
  }

  useEffect(() => {
    getType();
  }, []);

  async function getProduct() {
    const api = localApi + `/product/list/byAdminId/${userObj.admin.id}`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await result.json();
    setItemProd(getResult);
    // console.log(getResult);
  }

  useEffect(() => {
    getProduct();
  }, []);

  const handleOldNewClient = async (e) => {
    const { checked } = e.target;
    setCheckedA(checked);

    if (checked === true) {
      const api =
        localApi +
        `/register/list/byAdminAndTypeAndCrmReferenceIdAndOpenCloseCrm/${userObj.admin.id}/${userObj.id}/false`;
      const result = await fetch(api, {
        method: "GET",
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      });
      const getResult = await result.json();
      // console.log(getResult);
      setItemClient(getResult && getResult);
      // setItemClient(getResult.filter((r) => r.type !== "Crm" && r.crmReferenceId === userObj.id && r.openclosecrm === "false"));
    } else {
      const api =
        localApi + `/contact/list/byCrmReferenceIdAndActiveFalse/${userObj.id}`;
      const result = await fetch(api, {
        method: "GET",
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      });
      const getResult = await result.json();
      setItemClient(getResult);
    }
  };

  const handleProductDrop = async (e) => {
    const { checked } = e.target;
    setCheckedProd(checked);
  };

  const handleSimpleContact = (simpleSelectContact) => {
    if (simpleSelectContact.value) {
      let newSpli = simpleSelectContact.value.split("-");
      const ids = itemClient.find((f) => f.mobile === newSpli[1]);
      // console.log(ids);
      setContactDetails(ids);
      setSimpleSelectCategory(ids && ids.type);
      setSimpleSelectSource(ids && ids.source ? ids.source : "");
    }
    setSimpleSelectContact(simpleSelectContact);
    setSimpleSelectContactErr("");
  };

  const handleSimpleCategory = (e) => {
    const { value } = e.target;
    setSimpleSelectCategory(value);
    const itemz = typeItem.find((item) => item.title == value);
    setCategory(itemz);
  };

  const handleSimpleSource = (e) => {
    setSimpleSelectSource(e.target.value);
    setSimpleSelectSourceErr("");
  };

  const handleSimpleProductItem = (e) => {
    const { value } = e.target;
    setSimpleSelectProductItem(value);
    setSimpleSelectProductItemErr("");
    const itemz = itemProd.find((item) => item.id == value);
    setImage(itemz.productimage[0].cpanelPath);
    setProductDetails(itemz);
    setProdCategory(itemz.category.title);
    setProdSize(itemz.parent.title);
    setProdMetalGram(itemz.metalGram);
    setProdReadyGram(itemz.readyGram);
    setProdCash(itemz.cashPrice.toFixed(2));
    setProdTax(itemz.taxAll.toFixed(2));
    setProdTaxCash(itemz.taxInclude.toFixed(2));
  };

  const handleSimpleCallStatus = (e) => {
    setSimpleSelectCallStatus(e.target.value);
    setSimpleSelectCallStatusErr("");
  };

  const handleSimpleCallSubStatus = (e) => {
    setSimpleSelectCallSubStatus(e.target.value);
    setSimpleSelectCallSubStatusErr("");
  };

  const openAPi = [
    { id: 1, subStatus: "ACTIVE" },
    { id: 2, subStatus: "HOLD" },
    { id: 3, subStatus: "PENDING" },
    { id: 4, subStatus: "UNDER PROCESS" },
    { id: 5, subStatus: "PAYMENT" },
  ];

  const closeApi = [
    { id: 1, subStatus: "CANCLED" },
    { id: 2, subStatus: "SUCCESSFUL" },
    { id: 3, subStatus: "UNSUCCESSFUL" },
  ];

  const sourceApi = [
    { id: 1, subStatus: "ADVERTISEMENT" },
    { id: 2, subStatus: "EXHIBITION" },
    { id: 3, subStatus: "EXPORTS INDIA" },
    { id: 4, subStatus: "GOOGLE" },
    { id: 5, subStatus: "INDIAMART" },
    { id: 6, subStatus: "JUST DAIL" },
    { id: 7, subStatus: "MANUFACTURING FACTOR" },
    { id: 8, subStatus: "OTHERS" },
    { id: 9, subStatus: "PURCHASE" },
    { id: 10, subStatus: "SALES" },
    { id: 11, subStatus: "SEMINAR" },
    { id: 12, subStatus: "TRADESINDIA" },
    { id: 13, subStatus: "TRANSPORTER" },
    { id: 14, subStatus: "WEBSITE" },
  ];

  const handleSimpleNextAction = (e) => {
    setSimpleSelectNextAction(e.target.value);
    setSimpleSelectNextActionErr("");
  };

  const handleAfterDate = (AfterDate) => {
    setAfterDate(AfterDate);
    setAfterDateErr("");
  };

  const handleAfterTime = (AfterTime) => {
    setAfterTime(AfterTime);
    setAfterTimeErr("");
  };

  const SubmitRegisterAdd = async (e) => {
    const api = localApi + `/contact/byMobile/${contactDetails.mobile}`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await result.json();
    // console.log(getResult);

    e.preventDefault();
    let errorCount = 0;
    if (simpleSelectSource === "" || !simpleSelectSource) {
      errorCount++;
      setSimpleSelectSourceErr("Please Select Source");
    }
    if (errorCount === 0) {
      setBtn(true);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Basic ${credentials}`,
        },
        body: JSON.stringify({
          websitename: getResult.websitename,
          typeId: category.typeId || getResult.typeId,
          type: category.title || getResult.type,
          profit: category.profit || getResult.profit,
          firstname: getResult.firstname,
          lastname: getResult.lastname,
          mobile: getResult.mobile,
          whatsapp: getResult.whatsapp,
          email: getResult.email,
          company: getResult.company,
          city: getResult.city,
          state: getResult.state,
          country: getResult.country,
          smsMarketing: getResult.smsMarketing,
          countryCode: getResult.countryCode,
          isoNumber: getResult.isoNumber,
          source: simpleSelectSource,
          crmReferenceId: userObj.id,
          otp: {
            otp: 0,
            active: 0,
          },
        }),
      };

      fetch(
        localApi + `/register/add/byAdminId/${userObj.admin.id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.message === "Data Inserted Successfully !!!") {
            axios
              .put(
                localApi +
                  `/contact/update/byIdAndActiveTrue/${contactDetails.id}`,
                {},
                {
                  headers: {
                    Authorization: `Basic ${credentials}`,
                  },
                }
              )
              .then((res) => {
                // console.log(res.data);
                if (res.data.message === "Data Updated Successfully !!!") {
                  alertTypeOps("success");
                  alertMsgOps("Succesfully registerd!");
                  setSubmitted(true);
                }
              });
          } else if (data.message === "Email ID Already Registered") {
            alertTypeOps("danger");
            alertMsgOps("Already registerd!");
            setSubmitted(true);
          } else if (data.message === "Mobile No. Already Registered") {
            alertTypeOps("danger");
            alertMsgOps("Already registerd!");
            setSubmitted(true);
          }
        });
    }
  };

  const SubmitCallSave = (e) => {
    e.preventDefault();
    let errorCount = 0;
    // if (simpleSelectProductItem === "" || !simpleSelectProductItem) {
    //   errorCount++;
    //   setSimpleSelectProductItemErr("Please Select Product");
    // }
    if (simpleSelectContact === "" || !simpleSelectContact) {
      errorCount++;
      setSimpleSelectContactErr("Please Select Client");
    }
    if (simpleSelectCallStatus === "" || !simpleSelectCallStatus) {
      errorCount++;
      setSimpleSelectCallStatusErr("Please Select Call Status");
    }
    if (simpleSelectCallSubStatus === "" || !simpleSelectCallSubStatus) {
      errorCount++;
      setSimpleSelectCallSubStatusErr("Please Select Call Sub Status");
    }
    if (
      simpleSelectCallStatus === "OPEN"
        ? simpleSelectNextAction === ""
        : "" || simpleSelectCallStatus === "OPEN"
        ? !simpleSelectNextAction
        : ""
    ) {
      errorCount++;
      setSimpleSelectNextActionErr("Please Select Next Action");
    }
    if (
      simpleSelectCallStatus === "OPEN"
        ? AfterDate === ""
        : "" || simpleSelectCallStatus === "OPEN"
        ? !AfterDate
        : ""
    ) {
      errorCount++;
      setAfterDateErr("Please Select After Date");
    }
    if (
      simpleSelectCallStatus === "OPEN"
        ? AfterTime === ""
        : "" || simpleSelectCallStatus === "OPEN"
        ? !AfterTime
        : ""
    ) {
      errorCount++;
      setAfterTimeErr("Please Select After Time");
    }
    if (errorCount === 0) {
      setBtn(true);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Basic ${credentials}`,
        },
        body: JSON.stringify({
          registerId: contactDetails.id,
          firstname: contactDetails.firstname,
          lastname: contactDetails.lastname,
          mobile: contactDetails.mobile,
          whatsapp: contactDetails.whatsapp,
          company: contactDetails.company,
          state: contactDetails.state,
          adminId: userObj.admin.id,
          allocation: false,
          createdate: formatDate(new Date()),
          currentdate:
            new Date().toLocaleDateString() +
            " " +
            new Date().toLocaleTimeString(),
          receivedBy: userObj.firstname + " " + userObj.lastname,
          productId: checkedProd === true ? simpleSelectProductItem : null,
          callReceiptRemark: callReceiptRemarks,
          suggestedActionRemark: suggestedActionRemaks,
          callStatus: simpleSelectCallStatus,
          callSubStatus: simpleSelectCallSubStatus,
          finalstatus: simpleSelectCallStatus === "OPEN" ? 0 : 1,
          action:
            simpleSelectCallStatus === "OPEN" ? simpleSelectNextAction : null,
          afterdate:
            simpleSelectCallStatus === "OPEN" ? formatDate(AfterDate._d) : null,
          aftertime:
            simpleSelectCallStatus === "OPEN"
              ? AfterTime._d.toLocaleTimeString()
              : null,
        }),
      };

      fetch(
        localApi + `/callreceipt/add/byCrmReferenceId/${userObj.id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.message === "Data Inserted Successfully !!!") {
            axios
              .put(
                localApi +
                  `/register/update/byIdAndOpenCloseCrmTrueOrfalse/${
                    contactDetails.id
                  }/${simpleSelectCallStatus === "OPEN" ? "true" : "false"}`,
                {},
                {
                  headers: {
                    Authorization: `Basic ${credentials}`,
                  },
                }
              )
              .then((res) => {
                // console.log(res.data);
                if (res.data.message === "Data Updated Successfully !!!") {
                  alertTypeOps("success");
                  alertMsgOps("Succesfully !!!");
                  setSubmitted(true);
                }
              });
          }
        });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  const mystyle = {
    paddingLeft: 12,
    flex: 1,
  };

  const select = {
    width: "100%",
    maxWidth: 600,
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
    }),
    menu: (state) => ({
      color: "#d81b60",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  useEffect(() => {
    setSimpleSelectContact("");
    setSimpleSelectCategory("");
    setSimpleSelectSource("");
  }, [itemClient]);

  return (
    <div>
      <GridContainer>
        {submitted ? (
          <SweetAlert
            type={alertType}
            style={{ display: "block", marginTop: "-100px" }}
            title={alertMsg}
            onConfirm={() => hideAlert()}
            confirmBtnCssClass={classSweet.button + " " + classSweet.success}
          >
            Added Register
          </SweetAlert>
        ) : null}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <PersonPinCircleIcon />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Add Call Receipt Voucher</small>
              </h3>
            </CardHeader>
            <CardBody>
              <form>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={checkedA}
                          onChange={handleOldNewClient}
                          value="checkedA"
                          classes={{
                            switchBase: classe.switchBase,
                            checked: classes.switchChecked,
                            thumb: classe.switchIcon,
                            track: classe.switchBar,
                          }}
                        />
                      }
                      classes={{
                        label: classe.label,
                      }}
                      label={
                        checkedA === false ? (
                          "New Client"
                        ) : (
                          <span
                            style={{
                              color: checkedA === true ? "#d81b60" : "",
                            }}
                          >
                            Existing Client
                          </span>
                        )
                      }
                    />
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <label className={classe.selectLabel}>Select Client</label>
                    <Selects
                      styles={customStyles}
                      style={select}
                      required={true}
                      placeholder="Select Client..."
                      value={simpleSelectContact}
                      name="simpleSelectClient"
                      onChange={handleSimpleContact}
                      options={
                        itemClient &&
                        itemClient.map((c) => {
                          return {
                            label:
                              c && c.company
                                ? c.company + "-" + c.mobile
                                : c.firstname +
                                  " " +
                                  c.lastname +
                                  "-" +
                                  c.mobile,
                            value:
                              c && c.company
                                ? c.company + "-" + c.mobile
                                : c.firstname +
                                  " " +
                                  c.lastname +
                                  "-" +
                                  c.mobile,
                          };
                        })
                      }
                    />
                    <span style={{ color: "red" }}>
                      {simpleSelectContactErr}
                    </span>
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <FormControl fullWidth className={classe.selectFormControl}>
                      <InputLabel
                        htmlFor="simple-select"
                        className={classe.selectLabel}
                      >
                        Select Category...
                      </InputLabel>

                      <Select
                        MenuProps={{
                          className: classe.selectMenu,
                        }}
                        classes={{
                          select: classe.select,
                        }}
                        value={simpleSelectCategory}
                        onChange={handleSimpleCategory}
                        inputProps={{
                          name: "simpleSelectCategory",
                          id: "simple-select-category",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classe.selectMenuItem,
                          }}
                        >
                          Select Category
                        </MenuItem>
                        {typeItem.map((t) => (
                          <MenuItem
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                            key={t.typeId}
                            value={t.title}
                          >
                            {t.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <FormControl fullWidth className={classe.selectFormControl}>
                      <InputLabel
                        htmlFor="simple-select"
                        className={classe.selectLabel}
                      >
                        Select Source...
                      </InputLabel>

                      <Select
                        MenuProps={{
                          className: classe.selectMenu,
                        }}
                        classes={{
                          select: classe.select,
                        }}
                        value={simpleSelectSource}
                        onChange={handleSimpleSource}
                        inputProps={{
                          name: "simpleSelectSource",
                          id: "simple-select-source",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classe.selectMenuItem,
                          }}
                        >
                          Select Source
                        </MenuItem>
                        {sourceApi.map((s) => (
                          <MenuItem
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                            key={s.id}
                            value={s.subStatus}
                          >
                            {s.subStatus}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <span style={{ color: "red" }}>
                      {simpleSelectSourceErr}
                    </span>
                  </GridItem>
                </GridContainer>
                {checkedA === false ? (
                  btn === true ? (
                    <Button className={classes.updateProfileButton}>Add</Button>
                  ) : (
                    <Button
                      color="rose"
                      onClick={SubmitRegisterAdd}
                      className={classes.updateProfileButton}
                    >
                      Add
                    </Button>
                  )
                ) : (
                  ""
                )}
              </form>
            </CardBody>
          </Card>
        </GridItem>

        {checkedA === true ? (
          <>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="rose">
                    <PersonPinCircleIcon />
                  </CardIcon>
                  <h3 className={classes.cardIconTitle}>
                    <small>Product Item</small>
                  </h3>
                </CardHeader>
                <CardBody>
                  <form>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={checkedProd}
                              onChange={handleProductDrop}
                              value="checkedProd"
                              classes={{
                                switchBase: classe.switchBase,
                                checked: classes.switchChecked,
                                thumb: classe.switchIcon,
                                track: classe.switchBar,
                              }}
                            />
                          }
                          classes={{
                            label: classe.label,
                          }}
                          label={
                            checkedProd === false ? (
                              "New Client Website"
                            ) : (
                              <span
                                style={{
                                  color: checkedProd === true ? "#d81b60" : "",
                                }}
                              >
                                Existing Product
                              </span>
                            )
                          }
                        />
                      </GridItem>
                    </GridContainer>
                    {checkedProd === true ? (
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            fullWidth
                            className={classe.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classe.selectLabel}
                            >
                              Select Product Item...
                            </InputLabel>

                            <Select
                              MenuProps={{
                                className: classe.selectMenu,
                              }}
                              classes={{
                                select: classe.select,
                              }}
                              value={simpleSelectProductItem}
                              onChange={handleSimpleProductItem}
                              inputProps={{
                                name: "simpleSelectProductItem",
                                id: "simple-select-product-item",
                              }}
                            >
                              <MenuItem
                                disabled
                                classes={{
                                  root: classe.selectMenuItem,
                                }}
                              >
                                Select Product
                              </MenuItem>
                              {itemProd &&
                                itemProd.map((item) => (
                                  <MenuItem
                                    key={item.id}
                                    value={item.id}
                                    classes={{
                                      root: classe.selectMenuItem,
                                      selected: classe.selectMenuItemSelected,
                                    }}
                                  >
                                    {item.id}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                          <span style={{ color: "red" }}>
                            {simpleSelectProductItemErr}
                          </span>
                        </GridItem>
                        {simpleSelectProductItem ? (
                          <GridItem xs={12} sm={6} md={4}>
                            <img
                              src={image}
                              alt="Product Image"
                              style={{ width: "120px", height: "134px" }}
                            />
                          </GridItem>
                        ) : (
                          ""
                        )}
                        {simpleSelectProductItem ? (
                          <GridItem xs={12} sm={6} md={4}>
                            <span
                              className={classes.cardIconTitle}
                              style={{ fontSize: "18px", fontWeight: "bold" }}
                            >
                              Product Description
                            </span>
                            <br />
                            <span
                              className={classes.cardIconTitle}
                              style={{ fontSize: "15px" }}
                            >
                              Category:- {prodCategory} Size:- {prodSize} <br />
                              {(
                                productDetails.readyGram *
                                productDetails.sets.pcs
                              ).toFixed(4)}{" "}
                              Avg Gram Of {productDetails.sets.setDesc} {"and "}
                              {productDetails.box.boxDesc}
                              {","} {productDetails.ctnDescription}
                              <br />
                              Metal Gram:- {prodMetalGram} Ready Gram:-{" "}
                              {prodReadyGram} <br />
                              <span
                                className={classes.cardIconTitle}
                                style={{ fontSize: "15px", fontWeight: "bold" }}
                              >
                                Cash Price:- {prodCash} {" / Sets:- "}{" "}
                                {"₹" +
                                  (
                                    productDetails.cashPrice /
                                    productDetails.box.box
                                  ).toFixed(2)}
                              </span>
                              <br />
                              Tax:- {prodTax} <br />
                              <span
                                className={classes.cardIconTitle}
                                style={{ fontSize: "15px", color: "#d81b60" }}
                              >
                                TaxInclude:- {prodTaxCash} {" / Sets:- "}{" "}
                                {"₹" +
                                  (
                                    prodTaxCash / productDetails.box.box
                                  ).toFixed(2)}
                              </span>
                            </span>
                          </GridItem>
                        ) : (
                          ""
                        )}
                      </GridContainer>
                    ) : (
                      ""
                    )}
                  </form>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="rose">
                    <PersonPinCircleIcon />
                  </CardIcon>
                  <h3 className={classes.cardIconTitle}>
                    <small>Call Receipt Remarks</small>
                  </h3>
                </CardHeader>
                <CardBody>
                  <form>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Call Receipt Remarks..."
                          id="Call Receipt Remarks"
                          inputProps={{
                            placeholder: "Call Receipt Remarks...",
                            multiline: true,
                            rows: 5,
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          name="Call Receipt Remarks"
                          value={callReceiptRemarks}
                          onChange={(e) => {
                            setCallReceiptRemarks(e.target.value);
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="rose">
                    <PersonPinCircleIcon />
                  </CardIcon>
                  <h3 className={classes.cardIconTitle}>
                    <small>Suggested Action Remaks</small>
                  </h3>
                </CardHeader>
                <CardBody>
                  <form>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Suggested Action Remaks..."
                          id="Suggested Action Remaks"
                          inputProps={{
                            placeholder: "Suggested Action Remaks...",
                            multiline: true,
                            rows: 5,
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          name="Suggested Action Remaks"
                          value={suggestedActionRemaks}
                          onChange={(e) => {
                            setSuggestedActionRemarks(e.target.value);
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="rose">
                    <PersonPinCircleIcon />
                  </CardIcon>
                  <h3 className={classes.cardIconTitle}>
                    <small>Final Status</small>
                  </h3>
                </CardHeader>
                <CardBody>
                  <form>
                    <GridContainer>
                      <GridItem xs={12} sm={4} md={4}>
                        <FormControl
                          fullWidth
                          className={classe.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classe.selectLabel}
                          >
                            Select Call Status...
                          </InputLabel>

                          <Select
                            MenuProps={{
                              className: classe.selectMenu,
                            }}
                            classes={{
                              select: classe.select,
                            }}
                            value={simpleSelectCallStatus}
                            onChange={handleSimpleCallStatus}
                            inputProps={{
                              name: "stateSelectCallStatus",
                              id: "simple-select-call-status",
                            }}
                          >
                            <MenuItem
                              disabled
                              classes={{
                                root: classe.selectMenuItem,
                              }}
                            >
                              Select Call Status
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classe.selectMenuItem,
                                selected: classe.selectMenuItemSelected,
                              }}
                              value="OPEN"
                            >
                              OPEN
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classe.selectMenuItem,
                                selected: classe.selectMenuItemSelected,
                              }}
                              value="CLOSED"
                            >
                              Closed
                            </MenuItem>
                          </Select>
                        </FormControl>
                        <span style={{ color: "red" }}>
                          {simpleSelectCallStatusErr}
                        </span>
                      </GridItem>
                      <GridItem xs={12} sm={4} md={4}>
                        <FormControl
                          fullWidth
                          className={classe.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classe.selectLabel}
                          >
                            Select Call Sub Status...
                          </InputLabel>

                          <Select
                            MenuProps={{
                              className: classe.selectMenu,
                            }}
                            classes={{
                              select: classe.select,
                            }}
                            value={simpleSelectCallSubStatus}
                            onChange={handleSimpleCallSubStatus}
                            inputProps={{
                              name: "stateSelectCallSubStatus",
                              id: "simple-select-call-sub-status",
                            }}
                          >
                            <MenuItem
                              disabled
                              classes={{
                                root: classe.selectMenuItem,
                              }}
                            >
                              Select Call Sub Status
                            </MenuItem>
                            {simpleSelectCallStatus
                              ? simpleSelectCallStatus === "OPEN"
                                ? openAPi.map((o) => (
                                    <MenuItem
                                      classes={{
                                        root: classe.selectMenuItem,
                                        selected: classe.selectMenuItemSelected,
                                      }}
                                      key={o.id}
                                      value={o.subStatus}
                                    >
                                      {o.subStatus}
                                    </MenuItem>
                                  ))
                                : closeApi.map((c) => (
                                    <MenuItem
                                      classes={{
                                        root: classe.selectMenuItem,
                                        selected: classe.selectMenuItemSelected,
                                      }}
                                      key={c.id}
                                      value={c.subStatus}
                                    >
                                      {c.subStatus}
                                    </MenuItem>
                                  ))
                              : ""}
                          </Select>
                        </FormControl>
                        <span style={{ color: "red" }}>
                          {simpleSelectCallSubStatusErr}
                        </span>
                      </GridItem>
                      <GridItem xs={12} sm={4} md={4}>
                        {simpleSelectCallStatus === "OPEN" ? (
                          <>
                            <FormControl
                              fullWidth
                              className={classe.selectFormControl}
                            >
                              <InputLabel
                                htmlFor="simple-select"
                                className={classe.selectLabel}
                              >
                                Select Next Actoin...
                              </InputLabel>
                              <Select
                                MenuProps={{
                                  className: classe.selectMenu,
                                }}
                                classes={{
                                  select: classe.select,
                                }}
                                value={simpleSelectNextAction}
                                onChange={handleSimpleNextAction}
                                inputProps={{
                                  name: "stateSelectNextAction",
                                  id: "simple-select-next-action",
                                }}
                              >
                                <MenuItem
                                  disabled
                                  classes={{
                                    root: classe.selectMenuItem,
                                  }}
                                >
                                  Select Next Action
                                </MenuItem>

                                <MenuItem
                                  classes={{
                                    root: classe.selectMenuItem,
                                    selected: classe.selectMenuItemSelected,
                                  }}
                                  value="CALL"
                                >
                                  CALL
                                </MenuItem>
                                <MenuItem
                                  classes={{
                                    root: classe.selectMenuItem,
                                    selected: classe.selectMenuItemSelected,
                                  }}
                                  value="E MAIL"
                                >
                                  E-MAIL
                                </MenuItem>
                                <MenuItem
                                  classes={{
                                    root: classe.selectMenuItem,
                                    selected: classe.selectMenuItemSelected,
                                  }}
                                  value="SMS"
                                >
                                  SMS
                                </MenuItem>
                                <MenuItem
                                  classes={{
                                    root: classe.selectMenuItem,
                                    selected: classe.selectMenuItemSelected,
                                  }}
                                  value="SPECIFY LATER"
                                >
                                  SPECIFY LATER
                                </MenuItem>
                                <MenuItem
                                  classes={{
                                    root: classe.selectMenuItem,
                                    selected: classe.selectMenuItemSelected,
                                  }}
                                  value="SAMPLE"
                                >
                                  SAMPLE
                                </MenuItem>
                                <MenuItem
                                  classes={{
                                    root: classe.selectMenuItem,
                                    selected: classe.selectMenuItemSelected,
                                  }}
                                  value="VISIT"
                                >
                                  VISIT
                                </MenuItem>
                              </Select>
                            </FormControl>
                            <span style={{ color: "red" }}>
                              {simpleSelectNextActionErr}
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </GridItem>
                      <GridItem xs={12} sm={4} md={4}>
                        {simpleSelectCallStatus === "OPEN" ? (
                          <>
                            <InputLabel className={classes.label}>
                              When After Date
                            </InputLabel>
                            <br />
                            <FormControl fullWidth>
                              <Datetime
                                timeFormat={false}
                                dateFormat="YYYY-MM-DD"
                                inputProps={{ placeholder: "Date Picker Here" }}
                                onChange={(AfterDate) =>
                                  handleAfterDate(AfterDate)
                                }
                                name="AfterDate"
                                value={AfterDate}
                              />
                            </FormControl>{" "}
                            <span style={{ color: "red" }}>{AfterDateErr}</span>
                          </>
                        ) : (
                          ""
                        )}
                      </GridItem>
                      <GridItem xs={12} sm={4} md={4}>
                        {simpleSelectCallStatus === "OPEN" ? (
                          <>
                            <InputLabel className={classes.label}>
                              After Time
                            </InputLabel>
                            <br />
                            <FormControl fullWidth>
                              <Datetime
                                dateFormat={false}
                                inputProps={{ placeholder: "Time Picker Here" }}
                                onChange={(AfterTime) =>
                                  handleAfterTime(AfterTime)
                                }
                                name="AfterTime"
                                value={AfterTime}
                              />
                            </FormControl>{" "}
                            <span style={{ color: "red" }}>{AfterTimeErr}</span>
                          </>
                        ) : (
                          ""
                        )}
                      </GridItem>
                    </GridContainer>
                    {btn === true ? (
                      <Button className={classes.updateProfileButton}>
                        Save
                      </Button>
                    ) : (
                      <Button
                        color="rose"
                        onClick={SubmitCallSave}
                        className={classes.updateProfileButton}
                      >
                        Save
                      </Button>
                    )}
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </>
        ) : (
          ""
        )}
      </GridContainer>
    </div>
  );
}
